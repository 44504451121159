export default {
  data() {
    return {
      props: {
        'tag': '',
        'width': '700px',
        'maxWidth': '850px',
        'height': '500px',
        'minHeight': '950px',
        'border': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'borderRadius': '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'zIndex': 'auto',
        'rotation': '0deg',
        'calendar': {
          'src': '',
          'showInfo': false,
          'showGroup': false,
          'forceRedir': '',
          'typeRedir': 'page',
          'group': 0
        },
        'fullWidth': false,
        'fullHeight': false,
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        'width': '700px',
        'height': '500px',
        'maxWidth': '850px',
        'minHeight': '950px',
        'zIndex': 'auto',
        'rotation': '0deg',
        'border': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'borderRadius': '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'calendar': {
          'src': '',
          'showInfo': false,
          'showGroup': false,
          'forceRedir': '',
          'typeRedir': 'page',
          'group': 0
        },
        'fullWidth': false,
        'fullHeight': false,

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      }
    }
  }
}
