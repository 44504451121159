<template>
    <div id="app" class="panel-constructor invisible"
         :class="{ 'invisible-bg' : (hasRestrictArea && !allowedRestrictArea) || (!isAuth && actionLogin) }">
      <div class="is-android-bg" v-if="device === 'mobile' && !$isDeviceIOS()"></div>
      <template v-if="isDownloadPage">
        {{$t('ct-site-not-available-for-this-version')}}
      </template>
      <p class="text-center py-5" v-else-if="notAllowMembersArea">
        {{$t('ct-site-not-allow-members-area')}}
      </p>
      <template v-else>
        <template
                v-if="(hasRestrictArea && !allowedRestrictArea && !isAuth || !isAuth && actionLogin) && membersArea.open_by === 'page'">
            <restrict-area ref="restrictArea" :open_by="this.membersArea.open_by"
                           @cleanMembersArea="cleanMembersArea"></restrict-area>
        </template>

        <template v-else-if="hasRestrictArea && !allowedRestrictArea && isAuth">
            <restrict-area ref="restrictArea" :open_by="this.membersArea.open_by" @cleanMembersArea="cleanMembersArea"
                           :not_have_permission="!allowedRestrictArea" notHavePermission="true"></restrict-area>
        </template>

        <div id="editor-content" :data-device="device"
               v-else-if="!hasRestrictArea || hasRestrictArea && allowedRestrictArea || hasRestrictArea && membersArea.open_by === 'popup'">
              <div v-show="device === 'desktop'" hidden="true" id="page-mask"></div>
              <div class="editor-content-header-modify-on-scroll" v-if="elementHeaderSecondaryActive[device]">
                  <!-- HEADER INIT  - Begin -->
                  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                      <div v-show="!showHeaderSecondary">
                          <template v-for="(element,index) in elementHeader[device].content">
                              <template v-if="element.config[`show${capitalizeFirstLetter(device)}`]">
                                  <component class="editor-content-layout-item is-showed"
                                             :key="index + elementHeader[device]['header_id']"
                                             :is="element.name"
                                             :config="element.config"
                                             :device="device"
                                             is_popup="false"
                                             :is_footer="false"
                                             data-header-modify-on-scroll="init"
                                             :childs="element.childs"
                                  />
                              </template>
                          </template>
                      </div>
                  </transition>
                  <!-- HEADER INIT  - End -->

                  <!-- HEADER AFTER SCROLL  - Begin -->
                  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                      <div v-show="showHeaderSecondary">
                          <template v-for="(element,index) in elementHeaderSecondary[device].content">
                              <template v-if="element.config[`show${capitalizeFirstLetter(device)}`]">
                                <component class="editor-content-layout-item"
                                             :key="index + elementHeaderSecondary[device]['header_id']"
                                             :is="element.name"
                                             :config="element.config"
                                             :device="device"
                                             is_popup="false"
                                             :is_footer="false"
                                             data-header-modify-on-scroll="on-scroll"
                                             :childs="element.childs"
                                  />
                              </template>
                          </template>
                      </div>
                  </transition>
                  <!-- HEADER AFTER SCROLL  - End -->
              </div>

          <template v-else>
            <!-- HEADER  - Begin -->
            <component class="editor-content-layout-item" v-for="(element,index) in elementHeader[device].content"
                       :key="index + element.id"
                       :is="element.name" :config="element.config"
                       :device="device"
                       is_popup="false"
                       :is_footer="false"
                       :childs="element.childs">
            </component>
            <!-- HEADER  - End -->
          </template>


            <component v-for="(element,index) in elements" :key="index" :is="element.name" :config="element.config"
                       :device="device"
                       is_popup="false"
                       :is_footer="false"
                       :childs="element.childs">
            </component>

            <!-- FOOTER  - Begin -->
            <component class="editor-content-layout-item" v-if="elementFooter && elementFooter[device].content"
                       v-for="(element,index) in elementFooter[device].content" :key="index + element.id"
                       :is="element.name" :config="element.config"
                       :device="device"
                       is_popup="false"
                       :is_footer="true"
                       :childs="element.childs">
            </component>
            <!-- FOOTER  - End -->

            <sweet-modal v-for="(popup, index) in userPopups" :key="'popup' + popup.id" :id="'popup' + popup.id"
                         :ref="'popup' + popup.id"
                         :class="[popup.classList, popupStyleIOS]"
                         width="100%" v-on:close="yourHandlerForCloseUserPopup(popup)"
                         v-on:open="yourHandlerForOpenUserPopup(popup)">
                <div v-if="popup.active === 1">
                    <component v-for="(element,index2) in  JSON.parse(popup.content)" :key="index2" :is="element.name"
                               :device="device"
                               :is_popup="true"
                               :config="element.config"
                               :childs="element.childs">
                    </component>
                </div>
            </sweet-modal>

            <!-- MODAL DE VIDEO -->
            <div
                ref="videoModal"
                class="cheetah-modal-video modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-body mb-0 p-0">
                            <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                <iframe
                                    class="embed-responsive-item"
                                    ref="videoModalIframe"
                                    frameborder="0"
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    <div class="modal-footer justify-content-end">
                        <button
                            type="button"
                            class="btn btn-outline-primary rounded-pill"
                            data-dismiss="modal"
                            v-text="$t('ct-close-publication')"
                            @click="yourHandlerForCloseHere"
                        />
                    </div>
                    </div>
                </div>
            </div>
            <!-- MODAL DE VIDEO -->

            <sweet-modal icon="error" ref="required_fields" class="padding-modal">
                <div style="padding-top: 20px">
                    <p v-for="message in errorMessage">{{ message }}</p>
                </div>
            </sweet-modal>

            <template
                    v-if="(hasRestrictArea && !allowedRestrictArea && !isAuth || !isAuth && actionLogin) && membersArea.open_by === 'popup'">
                <!-- -->
                <restrict-area ref="restrictArea" :open_by="this.membersArea.open_by"
                               @cleanMembersArea="cleanMembersArea"></restrict-area>
            </template>

            <sweet-modal icon="success" ref="success_send" class="padding-modal">
                {{ successSendMessage != '' ? successSendMessage : 'Message sent successful' }}
            </sweet-modal>

        </div>

        <div class="modal-loading-overlay">
            <div class="loader-spinna"></div>
            <p class="d-none">{{$t('ct-office-magic-checkout')}}</p>
        </div>

        <sweet-modal ref="members_area_elearning" class="padding-modal">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <h5 class="mb-0 clean-text-css px-2" v-if="getMsg()">{{ getMsg() }}</h5>
                    <h5 class="mb-0 clean-text-css px-2" v-else>{{$t('ct-restrict-area-not-have-permission')}}</h5>
                </div>
            </div>
        </sweet-modal>

        <!-- GALLERY IMAGE -->
        <div class="gallery-overlay" v-if="galleryImage.overlay" @click="galleryImage.overlay = false">
            <img :src="galleryImage.image"/>
            <div class="gallery-icon">
                <button type="button" class="close" aria-label="Close">
                    <i class="fa fa-times-circle text-white fa-lg"></i>
                </button>
            </div>
        </div>

        <sweet-modal ref="browser-notification-alert" width="50%">
           <div class="d-flex justify-content-center align-items-center" style="min-height: 100px">
                <p v-if="bnStatus && bnStatus === 'denied'">{{ $t('ct-bn-status-denied') }}</p>
                <p v-if="bnStatus && bnStatus === 'granted'">{{ $t('ct-bn-status-granted') }}</p>
           </div>
        </sweet-modal>
      </template>
    </div>
</template>

<script>


import NotHavePermission from "./components/base/restrictArea/structure/NotHavePermission";
import PanelElement from "./components/apps/Panel/Panel.vue";
import RestrictArea from "./components/base/restrictArea/RestrictArea";
import {SweetModal} from 'sweet-modal-vue'
import {isMobile, isTablet} from 'mobile-device-detect';
import axios from 'axios';
import {check, logout} from "./js/WebsiteUser";
import {mixinRestrictArea} from "./js/mixins/restrictArea";
import {apiUrl} from './components/apps/Checkout/scTools'
import CurrentPage from "./js/CurrentPage";
import {getUrlRedirect, isImpact, urlSearchParams} from "@/js/UtilsWaterMark";

require('./components/base/editor/editor.scss')

var softCache = [];
const currentPage = new CurrentPage();

export default {
        name: 'app',
        components: {
            PanelElement,
            SweetModal,
            RestrictArea,
            NotHavePermission
        },
        mixins: [mixinRestrictArea],
        data() {
            return {
                isDownloadPage: false,
                notAllowMembersArea: false,
                isOnlyNecessary: false,
                isCreatedBtnReset: false,
                isAuth: false,
                actionLogin: false,
                auth: false,
                bnStatus: false,
                membersArea: {
                    redirect: '',
                    open_by: 'page',
                },
                galleryImage: {
                    overlay: false,
                    image: ''
                },
                hasRestrictArea: false,
                allowedRestrictArea: false,
                fixedStartPanel: 0,
                fixedStartPanelMobile: 0,
                device: isTablet || isMobile ? 'mobile' : 'desktop',
                errorMessage: [],
                elements: [],
                showHeaderSecondary: false,
                elementHeader: {mobile: {}, desktop: {}},
                elementHeaderSecondary: {mobile: {}, desktop: {}},
                elementFooter: {mobile: {}, desktop: {}},
                elementHeaderSecondaryActive: {mobile: false, desktop: false},
                userPopups: [],
                fontGroup: {},
                navigator: '',
                popupKeys: [],
                bgPropsPage: {},
                tagPage: {},
                popupSettings: {},
                popupStyle: '',
                popupStyleIOS: '',
                scrollPopup: false,
                isMobile: isMobile,
                isTablet: isTablet,
                cookieTypes: {
                    preferences: {
                        type: 'Site Preferences',
                        value: 'preferences',
                        description: 'These are cookies that are related to your site preferences, e.g. remembering your username, site colours, etc.'
                    },
                    analytics: {
                        type: 'Analytics',
                        value: 'analytics',
                        description: 'Cookies related to site visits, browser types, etc.'
                    },
                    marketing: {
                        type: 'Marketing',
                        value: 'marketing',
                        description: 'Cookies related to marketing, e.g. newsletters, social media, etc'
                    }
                },
                successSendMessage: ''
            }
        },

        watch: {
            'fixedStartPanel': function () {
                this.fixedPanel();
            },
            'fixedStartPanelMobile': function () {
                this.fixedPanel();
            },
            'device': function () {
                this.fixedPanel();
                this.headerScroll();
            }
        },

        methods: {
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            setPopupIfIos() {
                let isIOS = !!navigator.platform.toLowerCase().match(/iphone|ipod|ipad|macintel/);
                let agent = window.navigator.userAgent, start = agent.indexOf('Version/');

                if (start > -1 && isIOS && this.device === 'mobile') {
                    var versionIos = window.Number(agent.substr(start + 8, 2));

                    if (versionIos >= 15) {
                        this.popupStyleIOS = 'sweet-modal-helper-ios-15';
                    } else
                        this.popupStyleIOS = 'sweet-modal-helper-ios';
                }
            },
            getPlan() {
                if (!softCache['get-plan']) {
                    softCache['get-plan'] = axios.get('https://cheetah-api.builderall.com/get-plan/' + OPTIONS_INIT_SITE.options.id_usuario_omb10);
                }
                return softCache['get-plan'];
            },
          callFacebookPixel(value) {
            console.log(value)

            axios.get('/cheetah/pixel/get')
            .then( (res) => {
                let pathname = window.location.pathname.replace('/', '');

                if(pathname === '' && !value) {
                    pathname = pagesConfig.filter(page => {
                    if(page.initial == '1')
                        return true
                    });
                    pathname = pathname[0].url
                }


              if(!value) {

                res.data.forEach( (ev, idx) => {
                  if(ev.url_origin.replace('/', '') == pathname) {
                    // eslint-disable-next-line no-undef
                    if(typeof facebook_pixel_token !== 'undefined' && facebook_pixel_token !== '') {
                        console.log('pixel event api:' + ev.event);
                        let pixelEvent = '';
                        if (ev.event !== '') {
                            pixelEvent = ev.event;
                        }
                        if (ev.standard_event && ev.standard_event !== '') {
                            pixelEvent = ev.standard_event;
                        }
                        // if (ev.standard_event) {
                        //     if (ev.standard_event.length) {
                        //         ev.standard_event.forEach( (standardEvent) => {
                        //             pixelEvent= standardEvent;
                        //         })
                        //     }
                        // }
                        let url = `/cheetah/pixel/track?event_name=${pixelEvent}&token=${facebook_pixel_token}&id=${facebook_pixel}`;

                        let actualUrl = new URL(window.location.href)
                        let test_param = actualUrl.searchParams.get('test_event_code');
                        if(test_param !== null) {
                            url = url + `&test_event_code=${test_param}`
                        }
                        if(ev.standard_event && ev.standard_event == 'Purchase') {
                            url = url + `&currency=${ev.purchase_event_info.currency}&value=${ev.purchase_event_info.value}`
                        }
                        axios.get(url)
                            .then( res => { console.log(res.data) } )
                        return
                    } else {
                      setTimeout(() => {
                        if(typeof fbq !== 'undefined') {
                            if (ev.event !== '') {
                                fbq('trackCustom', ev.event);
                            }
                            if (ev.standard_event && ev.standard_event !== '') {
                                // fbq('track', ev.standard_event);
                                if (ev.standard_event == 'Purchase') {
                                    fbq('track', ev.standard_event, ev.purchase_event_info);
                                } else {
                                    fbq('track', ev.standard_event);
                                }
                            }
                        }
                        if(typeof gtag !== 'undefined'){
                            gtag('event', value);
                        }
                      }, 2000 )
                      return
                    }

                  }
                } )

              } else {

                console.log('pixel event:' + value);

                if(typeof facebook_pixel_token !== 'undefined' && facebook_pixel_token !== '') {
                //   let ev = {
                //     event : value
                //   }

                  let pixelEvent = '';
                  if (value.value !== '') {
                    pixelEvent = value.value;
                  }
                  if (value.standard_event && value.standard_event !== '') {
                    pixelEvent = value.standard_event;
                  }

                  let url = `/cheetah/pixel/track?event_name=${pixelEvent}&token=${facebook_pixel_token}&id=${facebook_pixel}`;

                  let actualUrl = new URL(window.location.href)
                  let test_param = actualUrl.searchParams.get('test_event_code');
                  if(test_param !== null) {
                    url = url + `&test_event_code=${test_param}`
                  }
                  if(value.standard_event && value.standard_event == 'Purchase') {
                    url = url + `&currency=${value.purchase_event_info.currency}&value=${value.purchase_event_info.value}`
                  }

                  axios.get(url)
                      .then( res => { console.log(res.data) } )
                  return
                }else{
                    if(typeof fbq !== 'undefined') {
                        if (value.value !== '') {
                            fbq('trackCustom', value.value);
                        }
                        if (value.standard_event && value.standard_event !== '') {
                            // fbq('track', value.standard_event);
                            if (value.standard_event == 'Purchase') {
                                fbq('track', value.standard_event, value.purchase_event_info);
                            } else {
                                fbq('track', value.standard_event);
                            }
                        }
                    }
                    //   if(typeof fbq !== 'undefined')
                    //         fbq('trackCustom', value);

                    if(typeof gtag !== 'undefined'){
                        gtag('event', value.value);
                    }

                }

              }

            } )


          },

            closeActivePopup() {
                let vm = this;
                this.userPopups.forEach(popup => {
                    if(vm.$refs['popup' + popup.id][0].$el.className.indexOf('is-visible')) {
                        vm.$refs['popup' + popup.id][0].close();
                    }
                })
            },

            callLinkAction(link) {
                console.log(link);
                this.$root.$emit('linkAction', link)
            },

          setPaddingFixedZIndex() {
            this.$nextTick(() => {

              var lastFooterFixed = $('.editor-content-layout-item[data-footer-fixed=true]');
              var lastFooterMobileFixed = $('.editor-content-layout-item[data-footer-fixed-mobile=true]');

              if (this.device === 'desktop' && lastFooterFixed.length > 0) {
                var lastPanelDesktop = $('#editor-content > .sitebuilder-element-panel:not(.hide-on-desktop):not([data-footer-fixed=true])').last();
                var countFooterFixed = lastFooterFixed.length;

                if (lastFooterFixed.length > 1) {
                  $(lastFooterFixed).each(function(i, elmt) {
                    if ((i + 1) !== countFooterFixed) {
                      $(elmt).removeAttr('data-footer-fixed');
                      $(elmt).removeAttr('data-footer-fixed-mobile');
                    }
                  });
                  lastFooterFixed = lastFooterFixed.last();
                }

                var scrollHeight = document.querySelector('#app').scrollHeight;
                var offsetHeight = document.querySelector('#app').offsetHeight;


                if (scrollHeight > offsetHeight) {
                  $(lastFooterFixed).addClass('scroll-fixed-z-index');
                }

                var heightPanel = lastFooterFixed.height();

                if (lastPanelDesktop !== lastFooterFixed) {
                  $(lastPanelDesktop).css({marginBottom: heightPanel});
                }
              }

              if (this.device === 'mobile' && lastFooterMobileFixed.length > 0) {
                var countFooterMobileFixed = lastFooterMobileFixed.length;
                var lastPanelMobile = $('#editor-content > .sitebuilder-element-panel:not(.hide-on-Mobile):not([data-footer-fixed-mobile=true])').last();
                if (lastFooterFixed.length > 1) {
                  $(lastFooterFixed).each(function(i, elmt) {
                    if ((i + 1) !== countFooterMobileFixed) {
                      $(elmt).removeAttr('data-footer-fixed');
                      $(elmt).removeAttr('data-footer-fixed-mobile');
                    }
                  });
                  lastFooterFixed = lastFooterFixed.last();
                }

                var scrollHeight = document.querySelector('#app').scrollHeight;
                var offsetHeight = document.querySelector('#app').offsetHeight;


                if (scrollHeight > offsetHeight) {
                  $(lastFooterFixed).addClass('scroll-fixed-z-index');
                }

                var heightPanel = lastFooterFixed.height();

                if (lastPanelMobile !== lastFooterFixed) {
                  $(lastPanelMobile).css({marginBottom: heightPanel});
                }
              }
            });
          },


          headerScroll() {
            if (this.elementHeaderSecondaryActive[this.device]) {

              this.$nextTick(() => {
                var $containerScroll = $('.editor-content-header-modify-on-scroll');
                var $header = $('[data-header-modify-on-scroll="init"]');
                var $headerSecondary = $('[data-header-modify-on-scroll="on-scroll"]');

                setTimeout(() => {
                  $containerScroll.addClass('active');

                  var $nav_height = $header.outerHeight(true);

                  if ($('#app').scrollTop() > $nav_height) {
                    this.showHeaderSecondary = true;
                    $header.removeClass('is-showed');
                    $headerSecondary.addClass('is-showed');
                    this.$root.$emit('setFixedStartPanel', $headerSecondary.height(), this.device);
                  } else {
                    this.showHeaderSecondary = false;
                    $headerSecondary.removeClass('is-showed');
                    $header.addClass('is-showed');
                    this.$root.$emit('setFixedStartPanel', $header.height(), this.device);
                  }
                }, 200);
              });
            }
          },

          fixedPanel() {
                this.$nextTick(() => {
                    var firstPanelDesktop = $('.sitebuilder-element-panel:not(.hide-on-desktop):not([data-header-modify-on-scroll])');
                    var firstPanelMobile = $('.sitebuilder-element-panel:not(.hide-on-mobile):not([data-header-modify-on-scroll])');

                    if (firstPanelDesktop)
                        firstPanelDesktop.removeClass('remove-margin-top').css('margin-top', '');

                    if (firstPanelMobile)
                        firstPanelMobile.removeClass('remove-margin-top').css('margin-top', '');

                    var panels = document.querySelectorAll('.sitebuilder-element-panel');

                    if (!panels)
                        return;

                    var isFreeWebsite = document.body.classList.contains('free-website');
                    var heightFreeBanner = 50;

                    if (this.device === 'desktop' && $('[data-fixed="true"]:not([data-header-modify-on-scroll])').length > 0) {

                        let firstEl = Array.from(panels)
                            .find(node => node.querySelector('div.panel:not([data-hide-desktop=true])'));

                        let desktopNextPanel = Array.from(panels)
                            .find(node => !node.hasAttribute('data-fixed') && node.querySelector('div.panel:not([data-hide-desktop=true])'));

                        let isIOSPlatform = !!navigator.platform.toLowerCase().match(/iphone|ipod|ipad|macintel/);
                        let agen = window.navigator.userAgent, strt = agen.indexOf('Intel Mac OS X 10_');
                        let version = window.Number(agen.substr(strt + 18, 2));

                        var scrollHeight = document.querySelector('#app').scrollHeight;
                        var offsetHeight = document.querySelector('#app').offsetHeight;

                        if ((scrollHeight > offsetHeight)) {
                            if (!(isIOSPlatform && version && version >= 14)) {
                                firstEl.classList.add('scroll-fixed-z-index');
                            }
                        }

                        if (desktopNextPanel !== firstEl)
                            desktopNextPanel.style.marginTop = (isFreeWebsite) ? parseInt(this.fixedStartPanel) + heightFreeBanner + 'px' : this.fixedStartPanel;

                    }

                    if (this.device === 'mobile' && $('[data-fixed-mobile="true"]:not([data-hide-mobile=true]):not([data-header-modify-on-scroll])').length > 0) {

                        let firstEl = Array.from(panels)
                            .find(node => node && node.querySelector('div.panel:not([data-hide-mobile=true])'));

                        let mobileNextPanel = Array.from(panels)
                            .find(node => !node.hasAttribute('data-fixed-mobile') && node.querySelector('div.panel:not([data-hide-mobile=true])'));

                        if (mobileNextPanel !== firstEl)
                            mobileNextPanel.style.marginTop = ((isFreeWebsite) ? $(firstEl).height() + heightFreeBanner : $(firstEl).height()) + 'px';
                    }
                });
            },

            removeLogin() {
                this.setLogout(false);

                if (this.membersArea.redirect && !!parseInt(this.membersArea.redirect)) {
                    pagesConfig.forEach(page => {
                        if (+this.membersArea.redirect === +page.id) {
                            window.location.href = '/' + page.url
                        }
                    });
                } else
                    window.location.reload();

                this.setAuth();
            },

            checkLogin() {

                let actialPage = this.getCurrentPage();
                let isBlog = false;

                /**
                 * page blog validation
                 */
                if(window.location.pathname.indexOf('blog-login') !== -1) {
                    this.hasRestrictArea = true;
                    isBlog = true;
                    this.membersArea.open_by = 'page';

                    let _self = this;
                   _self.$nextTick(() => {

                        if(typeof restrictsArea !== 'undefined' && restrictsArea.length > 0){
                            restrictsArea.forEach( (area) => {
                                if(area.is_blog == 1) {
                                    // console.log(area);
                                    _self.$refs.restrictArea.setAreas([parseInt(area.id)]);

                                }
                        } )
                        }
                    });

                    if (!this.$storageON()) {
                      this.notAllowMembersArea = true;
                    }

                    var login = !!(
                        localStorage.getItem('login-user') && localStorage.getItem('login-user-token')
                    );

                    if(login){
                        const { origin, search } = window.location;
                        const urlParams = new URLSearchParams(search);
                        const post = urlParams.get('post');

                        window.location.href = !!post ? `${origin}/${post}` : origin
                    }
                } else {

                    if (!actialPage)
                        return;

                    if (typeof actialPage.restrict_areas === 'undefined') {
                        axios.get(window.location.origin + '/cheetah/version')
                            .then((res => {
                                location.reload();
                            }))
                    }

                    if (!actialPage.restrict_areas && typeof restrictsArea !== 'undefined' && restrictsArea.length <= 0)
                        return;

                }

                if (actialPage.restrict_areas.length > 0)
                    this.hasRestrictArea = true;

                if (this.hasRestrictArea && !this.$storageON()) {
                  this.notAllowMembersArea = true;
                }

                var login = !!(
                    localStorage.getItem('login-user') && localStorage.getItem('login-user-token')
                );


                if ((login && this.hasRestrictArea) || (login && typeof restrictsArea !== 'undefined' && restrictsArea.length > 0) || (login && typeof actialPage.is_member !== 'undefined' && !!parseInt(actialPage.is_member)) || (login && isBlog)) {

                    this.$loading.show();

                    let data = {...JSON.parse(localStorage.getItem('login-user')), ...{'token': localStorage.getItem('login-user-token')}};

                    if (actialPage.restrict_areas.length > 0) {
                        data.page_id = actialPage.id;
                        data.page_url = actialPage.url;
                    }

                    check(data) // check token valid e area restrita
                        .then((res) => {

                            if (res.data.success) {

                                if (res.data.valid) {
                                    this.isAuth = true;

                                    if (actialPage.restrict_areas.length > 0 && res.data.restrict_areas) {
                                        this.allowedRestrictArea = !!res.data.restrict_areas.permission;
                                        if (!this.allowedRestrictArea && this.$refs.restrictArea) {
                                            this.$refs.restrictArea.changeType('not-have-permission');
                                        }
                                    }

                                    if (res.data.data)
                                        this.$refs.restrictArea.setLogin(res.data.data);
                                }
                            } else {
                                this.setLogout(false);

                                this.allowedRestrictArea = false;
                            }
                        })
                        .finally(() => {
                            this.$loading.hide();
                            this.setAuth();
                            this.headerScroll();
                            $('.panel-constructor').removeClass('invisible');
                        });
                }
            },

            checkRestrictArea() {
                return this.getCurrentPage().restrict_areas.length > 0;
            },

            setAuth() {
              if (this.$storageON()) {
                if (localStorage.getItem('login-user'))
                  this.auth = {...JSON.parse(localStorage.getItem('login-user'))};
                else this.auth = false;
              } else {
                this.auth = false;
              }
            },

            cleanMembersArea() {
                this.membersArea = {
                    redirect: '',
                    open_by: 'page',
                };

                this.actionLogin = false;

                this.$nextTick(() => {
                    $('.panel-constructor').removeClass('invisible');
                });

            },

            getTheme() {
                let activePage = this.getCurrentPage();
                if (activePage && activePage.website_form && activePage.website_form.length > 0) {
                    return activePage.website_form[0];
                } else
                    return {theme: 'default', config: {style: 'light'}};
            },

            getMsg() {
                let formConfig = this.getTheme();

                return (formConfig.config && formConfig.config.access_denied && formConfig.config.access_denied.message) ? formConfig.config.access_denied.message : false;
            },

            getAccessDenied(key, config) {
                let formConfig = this.getTheme();

                if(config === 'enable'){
                    return (formConfig && formConfig.config && formConfig.config.access_denied &&
                        formConfig.config.access_denied[key] &&
                        typeof formConfig.config.access_denied[key][config] !== 'undefined') ? parseInt(formConfig.config.access_denied[key][config]) : (config === 'enable');
                }
                return (formConfig && formConfig.config && formConfig.config.access_denied &&
                    formConfig.config.access_denied[key] &&
                    typeof formConfig.config.access_denied[key][config] !== 'undefined') ? formConfig.config.access_denied[key][config] : (config === 'enable');

            },

          setHeaderFooter() {
            var vm = this;
            function isJson(data) {
              try {
                JSON.parse(data);
              } catch (e) {
                return false;
              }
              return true;
            }
            function getHeaderItem(device, header_id) {
              let header = headersConst.find(header => parseInt(header.id) === parseInt(header_id));
              if (header && header.content) {
                vm.elementHeader[device] = header;
                vm.elementHeader[device]['header_id'] = +header_id;
                vm.elementHeader[device].content = (isJson(header.content)) ? JSON.parse(header.content) : header.content;
              }
            }
            function getSecondaryHeaderItem(device, header_id) {
              let header = headersConst.find(header => parseInt(header.id) === parseInt(header_id));
              if (header && header.content) {
                vm.elementHeaderSecondary[device] = header;
                vm.elementHeaderSecondary[device]['header_id'] = +header_id;
                vm.elementHeaderSecondary[device].content = (isJson(header.content)) ? JSON.parse(header.content) : header.content;
              }
            }
            function getFooterItem(device, footer_id) {
              let footer = footersConst.find(header => parseInt(header.id) === parseInt(footer_id));
              if (footer && footer.content) {
                vm.elementFooter[device] = footer;
                vm.elementFooter[device].content = (isJson(footer.content)) ? JSON.parse(footer.content) : footer.content;
              }
            }
            var actialPage = this.getCurrentPage();
            if (actialPage && typeof actialPage.configs !== 'undefined') {
              if (typeof headersConst !== 'undefined') {
                let headerSettings = actialPage.configs.find(config => config.key === 'headerSettings');
                if (headerSettings && headerSettings.value) {
                  let header = JSON.parse(headerSettings.value);
                  if (header) {
                    if (header.desktop.header_id !== 0) {
                      if (
                          typeof header.desktop.modify_on_scroll !== 'undefined' && header.desktop.modify_on_scroll &&
                          typeof header.desktop.secondary_header_id !== 'undefined' && header.desktop.secondary_header_id !== 0
                      ) {
                        vm.elementHeaderSecondaryActive['desktop'] = true;
                        getSecondaryHeaderItem('desktop', header.desktop.secondary_header_id);
                      }
                      getHeaderItem('desktop', header.desktop.header_id);
                    }
                    if (header.mobile.header_id !== 0) {
                      if (
                          typeof header.mobile.modify_on_scroll !== 'undefined' && header.mobile.modify_on_scroll &&
                          typeof header.mobile.secondary_header_id !== 'undefined' && header.mobile.secondary_header_id !== 0
                      ) {
                        vm.elementHeaderSecondaryActive['mobile'] = true;
                        getSecondaryHeaderItem('mobile', header.mobile.secondary_header_id);
                      }
                      getHeaderItem('mobile', header.mobile.header_id);
                    }
                  }
                }
              }
              if (typeof footersConst !== 'undefined') {
                let footerSettings = actialPage.configs.find(config => config.key === 'footerSettings');
                if (footerSettings && footerSettings.value) {
                  let footer = JSON.parse(footerSettings.value);
                  if (footer) {
                    if (footer.desktop.footer_id !== 0)
                      getFooterItem('desktop', footer.desktop.footer_id);
                    if (footer.mobile.footer_id !== 0)
                      getFooterItem('mobile', footer.mobile.footer_id);
                  }
                }
              }
            }
          },
            yourHandlerForCloseHere() {
                this.$refs.videoModalIframe.src = '';
                $('.cheetah-modal-video').modal('hide');
                // this.$refs.videoModalIframeMobile.src = '';
            },
            yourHandlerForCloseUserPopup(popup) {
                $('.sweet-modal-overlay').css('background', 'rgba(255, 255, 255, 0.9)')

                let vm = this;

                   setTimeout(()=> {
                let links = document.querySelectorAll('[element="TextComponent"] a[type]');


                let onClickEvent = function(ev) {

                    let target = ev.target;

                    if(typeof target.attributes.type === 'undefined') {
                        target = target.parentElement;
                    }

                                    // try{
                                    //     let link = {
                                    //     type: target.attributes.type.value,
                                    //     value: target.attributes.value.value,
                                    //     valueID:  target.attributes.valueID.value,
                                    //     target:  target.attributes.target.value,
                                    //     tel:  target.attributes.tel.value,
                                    //     telSelectedMask:  target.attributes.telSelectedMask.value,
                                    //     telOpenAs:  target.attributes.telOpenAs.value,
                                    //     secondaryLink: (typeof target.attributes.secondaryLink.value !== 'undefined') ? JSON.parse(target.attributes.secondaryLink.value) : false ,
                                    //     }
                                    //
                                    //
                                    //     $(target).off('click').on('click', () => {
                                    //         document.getElementById('app').__vue__.callLinkAction(link)
                                    //     })
                                    //
                                    //     }catch(e) {
                                    //         console.log(e)
                                    //     }



                }

                links.forEach( (link) => {
                    link.addEventListener('click',onClickEvent, false)
                                } )
                            }, 2000 )


                $('#popup' + popup.id + ' iframe').each((idx, elem) => {
                    $(elem).attr('data-src', $(elem).attr('src'));
                    $(elem).attr('src', null);
                });

                popup.active = 0;
            },
            popupOverlayStyle(popup) {

                 try{
                        /**
                        * configuração de cor do overlay das popups
                        * aplica default sempre e depois trata se há outra cor configurada
                        */
                        $('.sweet-modal-overlay').css('background', 'rgba(255, 255, 255, 0.9)')
                        $('.sweet-modal').css('background', '#fff')
                        $('.sweet-modal').css('box-shadow', '0 8px 46px rgb(0 0 0 / 8%), 0 2px 6px rgb(0 0 0 / 3%)')
                        $('.sweet-modal').css('-webkit-box-shadow', '0 8px 46px rgb(0 0 0 / 8%), 0 2px 6px rgb(0 0 0 / 3%)')
                        $('.sweet-action-close').css('background', 'none')
                        $('.sweet-action-close').css('color', '#000')


                        let configurations = JSON.parse(popup.content);
                        if(typeof configurations === 'object' && typeof configurations[0] === 'object') {
                            configurations = configurations[0];

                            if(configurations.hasOwnProperty('config')) {
                                if(configurations.config.hasOwnProperty('overlayCustom') && configurations.config.hasOwnProperty('overlayColor')) {
                                    if(configurations.config.overlayCustom) {
                                        $('.sweet-modal').css('background', 'none')
                                        $('.sweet-modal').css('box-shadow', 'none')
                                        $('.sweet-modal').css('-webkit-box-shadow', 'none')
                                        $('.sweet-modal-overlay').css('background', configurations.config.overlayColor)
                                        $('.sweet-modal-overlay').css('background', configurations.config.overlayColor)
                                        $('.sweet-action-close').css('background', configurations.config.buttonColor)
                                        $('.sweet-action-close').css('color', configurations.config.buttonIcon)
                                    }
                                }
                            }
                        }

                    }catch(e) {
                        console.log(e)
                    }

            },
            yourHandlerForOpenUserPopup(popup) {
                this.popupOverlayStyle(popup);
                popup.active = 1;
                urlParamParse.parse(500)

                 let vm = this;
                this.$nextTick(() => {

                    $('#popup' + popup.id + ' iframe').each((idx, elem) => {
                        $(elem).attr('src', $(elem).attr('data-src'));
                    });

                    $('#popup' + popup.id + ' .invisible').each((idx, elem) => {
                        $(elem).removeClass('invisible')
                    });

                    // setTimeout( () => { urlParamParse.parse(500) }, 1000 )

                //   setTimeout(()=> {
                //     document.querySelectorAll('.sweet-content-content a').forEach( (target) => {

                //         try{
                //             let link = {
                //             type: target.attributes.type.value,
                //             value: target.attributes.value.value,
                //             valueID:  target.attributes.valueID.value,
                //             target:  target.attributes.target.value,
                //             tel:  target.attributes.tel.value,
                //             telSelectedMask:  target.attributes.telSelectedMask.value,
                //             telOpenAs:  target.attributes.telOpenAs.value,
                //             secondaryLink: (typeof target.attributes.secondaryLink.value !== 'undefined') ? JSON.parse(target.attributes.secondaryLink.value) : false ,
                //             }


                //             $(target).off('click').on('click', () => {
                //                 document.getElementById('app').__vue__.callLinkAction(link)
                //             })

                //             }catch(e) {
                //                 console.log(e)
                //             }

                //     } )
                //   }, 1000 )

                });

                urlParamParse.parse(500)

           },

            applyFontGroup: function () {
                Object.keys(this.fontGroup).forEach(font => {
                    document.body.style.setProperty('--text-family-' + font, this.fontGroup[font].family);
                    document.body.style.setProperty('--text-size-' + font, this.fontGroup[font].size);
                    document.body.style.setProperty('--text-size-mobile-' + font, this.fontGroup[font].sizeMobile);
                    document.body.style.setProperty('--text-color-' + font, this.fontGroup[font].color);
                    document.body.style.setProperty('--text-line-height-' + font, this.fontGroup[font]['line-height']);
                });
            },
            setMbossReference(pageData) {

                try {
                    if (typeof pageData.mboss_reference != 'undefined' && pageData.mboss_reference != null) {

                        localStorage.setItem('MAILING_BOSS_TRACKING', true);
                        localStorage.setItem('MAILING_BOSS_REFERENCE', pageData.mboss_reference);
                        localStorage.setItem('MAILING_BOSS_TOKEN', pageData.mboss_token);

                        var s = document.createElement("script");
                        s.type = "text/javascript";
                        s.src = "https://app.mailingboss.com/js/tracking.min.js";
                        $("head").append(s);

                        setTimeout(() => {
                            console.log('insert mboss');
                            var options = {
                                leadEmail: localStorage.getItem('leadEmail'),
                                reference: localStorage.getItem('MAILING_BOSS_REFERENCE')
                            }
                            MailingBossTracking.init(localStorage.getItem('MAILING_BOSS_TOKEN'), options);
                        }, 3000);
                    } else {
                        localStorage.removeItem('MAILING_BOSS_REFERENCE');
                        localStorage.removeItem('MAILING_BOSS_TOKEN');
                        localStorage.removeItem('MAILING_BOSS_TRACKING');
                    }
                }
                catch (e) {
                    console.log(e)
                }




            },
            applyBackground() {

                let bg = this.bgPropsPage.backgroundColor;

                if (this.$globalStoreHelpers.isValidHex(this.bgPropsPage.backgroundColor)) {
                    this.bgPropsPage.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.bgPropsPage.backgroundColor);
                }

                if (this.bgPropsPage.backgroundImage !== 'none' && this.bgPropsPage.backgroundImage) {
                    bg += ' url("' + this.bgPropsPage.backgroundImage + '")';
                    bg += ' ' + this.bgPropsPage.backgroundPosition;
                    bg += ' / ' + this.bgPropsPage.backgroundSize;
                    bg += ' ' + this.bgPropsPage.backgroundRepeat;
                    bg += ' ' + this.bgPropsPage.backgroundOrigin;
                    if (typeof this.bgPropsPage.backgroundAttachment !== 'undefined') {
                        bg += ' ' + this.bgPropsPage.backgroundAttachment;
                    }
                }


                document.body.style.setProperty('--bg-page', bg);
            },

			applyTags() {

				Object.keys(this.tagPage).forEach(idx => {
					let tag = this.tagPage[idx];
					let seconds = (~~tag.min * 60 * 1000) + (~~tag.sec * 1000)

					setTimeout(() => {
						let obj = {
							email: localStorage.getItem('leadEmail'),
							list: tag.list,
							tag_value: idx,
							websiteID: websiteID
						}

						axios({
							'method': 'post',
							'url': '/cheetah/tag',
							data: obj,
						}).then((res) => {
							console.log(res);
						});

					}, seconds)
				})

			},

            applyPopups() {

                class EventHandlerClass {
                    constructor() {
                        this.functionMap = {};
                    }

                    addEventListener(event, func) {
                        this.functionMap[event] = func;
                        document.addEventListener(event.split('.')[0], this.functionMap[event]);
                    }

                    removeEventListener(event) {
                        document.removeEventListener(event.split('.')[0], this.functionMap[event]);
                        delete this.functionMap[event];
                    }
                }

                const EventHandler = new EventHandlerClass();

                let vm = this;

                let $popup = this.isMobile ? this.popupSettings.mobile : this.popupSettings.desktop;

                function getPopUpStyle(config, is_return = false) {

                    let popupStyle = '';

                    if (typeof config.overlay !== 'undefined' && !parseInt(config.overlay))
                        popupStyle = popupStyle + 'overlay-none ';

                    if (typeof config.size !== 'undefined' && config.size === 'full-size') {
                        popupStyle = popupStyle + 'full-size ';

                        if (typeof config.maxHeight !== 'undefined' && config.maxHeight && typeof config.direction !== 'undefined' && config.direction !== 'center')
                            popupStyle = popupStyle + 'max-height-' + config.maxHeight;

                        if (typeof config.direction !== 'undefined' && config.direction)
                            popupStyle = popupStyle + ' direction-' + config.direction;
                    }

                    if(is_return) {
                      return popupStyle;
                    }

                    vm.popupStyle = popupStyle;
                }

                this.userPopups.forEach(popup => {
                    if ($popup.popup_id == popup.id) {
                        popup.classList = getPopUpStyle($popup, true);
                        if ($popup.type === 'time') {
                            setTimeout(function () {


                            vm.$refs['popup' + popup.id][0].open();

                            }, ((parseInt($popup.min * 60)) + parseInt($popup.sec)) * 1000)
                        }
                        if ($popup.type === 'scroll') {

                            var scrollListener = function () {
                                function showPosition(elm) {
                                    let parent = elm.parentNode,
                                        pos = (elm.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight) * 100;

                                    return parseInt(pos);
                                }

                                let pos = showPosition(document.getElementById('editor-content'));
                                if (pos >= $popup.scroll && !vm.scrollPopup) {
                                    vm.$refs['popup' + popup.id][0].open();

                                    vm.scrollPopup = true;
                                }
                            };

                            document.addEventListener('scroll', scrollListener, true /*Capture event*/);
                        }
                        if ($popup.type === 'exit') {
                            EventHandler.addEventListener("mouseout.popup", evt => {
                                if ((evt.toElement === null || typeof evt.toElement === 'undefined') && evt.relatedTarget === null && evt.target.tagName !== 'INPUT') {
                                    vm.$refs['popup' + popup.id][0].open();

                                    EventHandler.removeEventListener("mouseout.popup", () => {
                                    });
                                }
                            });
                        }
                    }
                })
            },

            printScripts(scripts) {
                if (!this.isCreatedBtnReset && typeof gdprSettings !== 'undefined' && gdprSettings.active && gdprSettings.activeResetCookies) {

                    let vm = this;
                    let button = $('<button>')
                        .text(gdprSettings.btnLabelResetCookies)
                        .off('click')
                        .attr({
                            id: "gdpr-reset-cookies",
                            display: "block",
                        })
                        .on('click', () => {

                            vm.delete_cookie('cookieControl');
                            vm.delete_cookie('cookieControlPrefs');

                            window.location.reload();
                        })
                        .addClass('gdpr-reset-cookies')
                        .appendTo('#editor-content');

                    if (gdprSettings.positionResetCookies === 'right') {
                        button.css('right', '12%');
                    }

                    this.isCreatedBtnReset = true;


                }


                try{



                    if (scripts.indexOf('marketing') > -1) {
                            if (typeof layoutScriptsFb !== 'undefined') {
                                try {
                                    $(layoutScriptsFb).appendTo($("head"));
                                } catch (e) {
                                    console.log('broken layoutScriptsFb')
                                }
                            }

                            if(typeof facebook_pixel !== 'undefined' && facebook_pixel !== '') {
                                try {
                                    axios.get(`/cheetah/pixel/facebook?pixel-id=${facebook_pixel}`)
                                        .then( res => {
                                        $(res.data).appendTo($("head"));
                                        } )
                                }catch (e) {
                                    console.log('broken facebook pixel')
                                }
                            }
                            if(typeof google_analytics !== 'undefined' && google_analytics !== '' && typeof window.ga_loaded === 'undefined') {
                              window.ga_loaded = true;
                                try {
                                axios.get(`/cheetah/pixel/analytics?pixel-id=${google_analytics}`)
                                    .then( res => {
                                        $(res.data).appendTo($("head"));
                                    } )
                                }catch (e) {
                                console.log('broken analytics pixel')
                                }
                            }
                            if(typeof tag_manager !== 'undefined' && tag_manager !== '' && typeof window.gtm_loaded === 'undefined') {
                                window.gtm_loaded = true;
                                try {
                                axios.get(`/cheetah/pixel/tag-manager?pixel-id=${tag_manager}`)
                                    .then( res => {
                                        $(res.data).appendTo($("head"));

                                    } )
                                }catch (e) {
                                console.log('broken tag manager pixel')
                                }

                            }
                        }


                    //   let tag_manager = 'GTM-PPSJMC4';

                    if (scripts.indexOf('analytics') > -1) {
                            if (typeof layoutScriptsGa !== 'undefined') {
                                try {
                                    $(layoutScriptsGa).appendTo($("head"));
                                } catch (e) {
                                    console.log('broken layoutScriptsGa')
                                }
                            }
                    }
                    if (scripts.indexOf('necessary') > -1) {
                            if (typeof layoutScripts !== 'undefined') {
                                try {
                                    $(layoutScripts).appendTo($("head"));
                                } catch (e) {
                                    console.log('broken layoutScripts')
                                }
                            }
                            if (typeof pageScripts !== 'undefined') {
                                try {
                                    $(pageScripts).appendTo($("head"));
                                } catch (e) {
                                    console.log('broken pageScripts')
                                }
                        }
                    }

                        }catch(e) {
                            console.log(e)
                        }



                        if (typeof OPTIONS_INIT_SITE !== 'undefined' &&
                            typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                            typeof OPTIONS_INIT_SITE.options.crmEnable !== 'undefined' &&
                            OPTIONS_INIT_SITE.options.crmEnable === '1'
                        ) {

                            let urlCrm = 'https://crm-api.eb4us.com/js/tracking.min.js?v=' + new Date().getTime() ;

                            var script = document.createElement("script");
                            script.innerHTML = "(function({d=document,s='script',id='bacrmtrk', cKey, oKey}){\n" +
                                "    let n, p = d.getElementsByTagName(s)[0];\n" +
                                "    if (d.getElementById(id)) {return;}\n" +
                                "    n = d.createElement(s); n.id = id;\n" +
                                "    n.src = \" " + urlCrm + " \";\n" +
                                "    n.onload = function(){__baCRMTracking.init({cKey, oKey})}\n" +
                                "    p.parentNode.insertBefore(n, p);\n" +
                                "  }({}));";
                            document.head.appendChild(script);
                        }
            },
            delete_cookie(name) {
                document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            },

            getCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },

            setLang() {

                // try {
                // 	var lang = false;
                // 	var i18n = this.$i18n;
                //
                // 	if (typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                // 		typeof OPTIONS_INIT_SITE.options.lang !== 'undefined') {
                // 		lang = OPTIONS_INIT_SITE.options.lang;
                // 	}
                //
                // 	if (lang && lang !== 'en_US') {
                //
                // 		getTranslateCheetah(lang)
                // 			.then(response => {
                // 				if (response.data.success) {
                // 					var _translations = false;
                // 					if (window.editorTranslate && window.editorTranslate['en_US']) {
                // 						_translations = {
                // 							...window.editorTranslate['en_US'],
                // 							...response.data.data
                // 						};
                // 					} else _translations = response.data.data;
                //
                // 					i18n.setLocaleMessage(lang, _translations);
                //
                // 					i18n.locale = lang;
                // 				}
                // 			})
                // 			.catch(error => {
                // 				console.error(error);
                // 			});
                // 	}
                // } catch (e) {
                // 	console.warn(e);
                // }
            },

            getInfoToSetWaterMark(langW, sideW) {
                let urlRedir = getUrlRedirect(langW)

                if (
                    typeof OPTIONS_INIT_SITE !== 'undefined' &&
                    typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                    typeof OPTIONS_INIT_SITE.options.id_usuario_omb10 !== 'undefined'
                ) {
                    this.getPlan().then((res) => {
                        $('<img>')
                            .attr('src', '/static/powered_by.png')
                            .off('click')
                            .on('click', () => {
                              let aid = ''
                              console.log('isImpact()', isImpact())
                              if(isImpact()) {
                                aid = '2494737'
                              } else if (typeof OPTIONS_INIT_SITE.options.id_office_user !== 'undefined') {
                                aid = OPTIONS_INIT_SITE.options.id_office_user
                              } else if (typeof aid_office !== 'undefined') {
                                aid = aid_office
                              } else if (res.data.data.is_free === '1') {
                                aid = res.data.data.aid;
                              } else {
                                aid = res.data.data.id_office_user;
                              }

                              let queryParams = urlSearchParams()
                              queryParams.set('spec', 'watermark');
                              window.open(`${urlRedir}?${queryParams.toString()}#aid=${aid}`);
                            })
                            .addClass('img-watermark img-side-' + sideW)
                            .appendTo('#editor-content');
                    })
                } else {
                    $('<img>')
                        .attr('src', '/static/powered_by.png')
                        .off('click')
                        .on('click', () => {
                            window.open(urlRedir + '?spec=watermark');
                        })
                        .addClass('img-watermark img-side-' + sideW)
                        .appendTo('#editor-content');
                }
            },
            setWaterMark() {

                if (typeof websiteID !== 'undefined' && typeof websiteID !== '') {
                    axios.get('https://cheetah-api.builderall.com/get-watermark/' + websiteID).then((res) => {
                        if (res.data.data.watermark == '1') {
                            this.getInfoToSetWaterMark(res.data.data.watermark_lang, res.data.data.watermark_side);
                        }
                    })
                }
            },

			setSplitTestData(){
                axios.get('/cheetah/split-test')
                    .then(res => {
                        // console.log(res);
                    });
            },

			setBannerFree() {

                let vm = this;
                if (
                    typeof OPTIONS_INIT_SITE !== 'undefined' &&
                    typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                    typeof OPTIONS_INIT_SITE.options.id_usuario_omb10 !== 'undefined'
                ) {


                    this.getPlan()
                        .then((res) => {
                            // console.log('res 1', res);
                            if (res.data.data.is_free == '1' || OPTIONS_INIT_SITE.options.id_usuario_omb10 === '181114') {

                                $('body').addClass('free-website');
                                let bannerFree = $('<div>').addClass('banner-free w-100').appendTo('body');

                                $('<small>').text(vm.$t('banner-free-first-text')).addClass('banner-free-first-text').appendTo(bannerFree);
                                $('<small>').text(vm.$t('banner-free-second-text')).addClass('banner-free-second-text').appendTo(bannerFree);
                                $('<img>').attr('src', 'https://builderall.com/franquias/2/73748/editor-html/3589641.png').addClass('banner-free-img').appendTo(bannerFree);
                                $('<small>').text(vm.$t('banner-free-third-text')).addClass('banner-free-third-text').appendTo(bannerFree);
                                $('<button>')
                                    .text(vm.$t('banner-free-btn-text'))
                                    .off('click')
                                    .on('click', () => {
                                        window.open('https://builderall.com/free?spec=free#aid=' + res.data.data.aid);
                                    })
                                    .addClass('btn-banner-free')
                                    .appendTo(bannerFree);
                            }
                        })

                }
            },

            redirectAid(link) {

                // console.log('entrou')

                // const params = new URLSearchParams(window.location.search);
                // const queryString = '?' + params.toString();

                if (link.type === 'web' && link.value !== '') {

                    const aid = OPTIONS_INIT_SITE.options.id_office_user
                        ? `#aid=${OPTIONS_INIT_SITE.options.id_office_user}`
                        : window.aid_office

                    if (link.target === '_blank') {
                        return window.open(link.value + aid, '_blank');
                    }

                    window.location.href = link.value + aid
                } else {
                    pagesConfig.forEach(page => {
                        if (link.valueID == page.id) {

                            const url = window.aid_office
                                ? page.url + window.aid_office
                                : page.url

                            if (link.target === '_blank') {
                                return window.open('/' + url, '_blank');
                            }

                            window.location.href = '/' + url
                        }
                    });
                }
            },

            getPosition(element) {
                var clientRect = element.getBoundingClientRect();
                return {
                    left: clientRect.left + document.body.scrollLeft,
                    top: clientRect.top + document.body.scrollTop
                };
            },

            scrollToElement(idEl) {

                let vm = this;
                var countImage = 10;
                let idUsuarioOmb10 = typeof OPTIONS_INIT_SITE !== 'undefined' ? OPTIONS_INIT_SITE.options.id_usuario_omb10 : false;

                try {
                    idEl = decodeURI(idEl);

                    countImage = document.querySelectorAll('[element="ImageComponent"]').length;
                } catch (e) {}

                setTimeout(function () {
                    const el = document.querySelector('[data-anchor-name="'+idEl+'"]');

                    if (el) {

                        let yOffset = 0;

                        if (vm.device === 'desktop' && vm.fixedStartPanel && parseInt(vm.fixedStartPanel) !== 0) {
                            yOffset = parseInt(vm.fixedStartPanel);
                        } else if (vm.fixedStartPanelMobile && parseInt(vm.fixedStartPanelMobile) !== 0) {
                            yOffset = parseInt(vm.fixedStartPanelMobile);
                        }

                        const element = document.querySelector('[data-anchor-name="'+idEl+'"]');

                        const y = vm.getPosition(element).top + (yOffset * -1) + (document.getElementById('app').scrollTop);

                        $(document.getElementById('app')).animate({
                            scrollTop: y
                        }, 1000);
                        //document.getElementById('app').scrollTo({top: y, behavior: 'smooth'});
                    }
                }, (countImage > 40 || ['6664201'].includes(idUsuarioOmb10)) ? 800 : 200);
            },

            async tagsAdvancedLink(id_usuario_omb10) {

                if (!softCache[`tags-advanced-link-${id_usuario_omb10}`]) {
                    softCache[`tags-advanced-link-${id_usuario_omb10}`] = await new Promise(resolve => {
                        var xhttp = new XMLHttpRequest();
                        xhttp.open(
                            "POST",
                            "https://office.builderall.com/us/office/user/get-tags/" + id_usuario_omb10,
                            true
                        );

                        xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                        xhttp.onload = function () {
                            if (xhttp.readyState == 4 && xhttp.status == 200) {
                                var res = JSON.parse(xhttp.responseText);
                                resolve(res);
                            }
                        };
                        xhttp.onerror = function () {
                            resolve(false);
                        };
                        xhttp.withCredentials = true;
                        xhttp.send();

                    });
                }

                return softCache[`tags-advanced-link-${id_usuario_omb10}`];
            },

            replaceLinkWithTags() {


                let buttoncomporntn = document.querySelectorAll('[element="ButtonComponent"]');
                let hasTagLink = false;
                buttoncomporntn.forEach(element => {
                    var link = element.querySelectorAll('a');

                    link.forEach(le => {
                        var myRe = /\[([^}]*?)]/g;

                        if (!!le.href) {
                            var myArray = myRe.exec(le.href);
                            if (!!myArray) {
                                hasTagLink = true;
                            }
                        }
                    })
                });

                if (hasTagLink && typeof OPTIONS_INIT_SITE !== 'undefined' && !!OPTIONS_INIT_SITE.options.id_usuario_omb10) {
                    var links = document.querySelectorAll('a.bt-text-area');
                    this.tagsAdvancedLink(OPTIONS_INIT_SITE.options.id_usuario_omb10)
                        .then(response => {
                            console.log('tagsAdvancedLink -> response', response);
                            links.forEach(le => {
                                var myRe = /\[([^}]*?)]/g;

                                if (!!le.href) {
                                    var myArray = myRe.exec(le.href);
                                    let justTag = false;
                                    if (!!myArray) {
                                        var linkReplace = le.href;


                                      if(linkReplace.replace(window.location.origin + '/', '').indexOf('https://') === -1) {
                                        justTag = true;
                                      }


                                        Object.keys(response).forEach(function (item) {
                                            if (!!response[item]) { // diferente de null

                                                if (typeof response[item] === 'string') {

                                                    linkReplace = linkReplace.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
                                                        return group.toLowerCase() in response ? encodeURI(response[group.toLowerCase()]) : match
                                                    })
                                                } else if (typeof response[item] === 'object') {

                                                    linkReplace = linkReplace.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
                                                        return group.toLowerCase() in response[item] ? encodeURI(response[item][group.toLowerCase()]) : match
                                                    })
                                                }
                                            }
                                        });

                                      if(justTag) {
                                        linkReplace = linkReplace.replace(window.location.origin + '/', '')
                                      }

                                      le.setAttribute('href', linkReplace);


                                        le.classList.add("overwrite-click");
                                        le.childNodes.forEach( elm => elm.classList.add("overwrite-click") );
                                    }
                                }
                            })

                        });
                }
            }

        },
        created() {

          if(!['https:', 'http:'].includes(window.location.protocol)){
            this.isDownloadPage = true;
            return;
          }

            if (typeof popupConst !== 'undefined')
                this.userPopups = popupConst;

            this.fontGroup = fontConfig;

            this.setBannerFree();
            this.setHeaderFooter();

            if (typeof socialProofSettings !== 'undefined' && socialProofSettings && this.$isJson(socialProofSettings)){

                let actialPage = this.getCurrentPage();
                var socialProofConfig = JSON.parse(socialProofSettings);

                if(socialProofConfig.pages && socialProofConfig.pages.includes(parseInt(actialPage.id))){

                    let s = document.createElement('script');
                    s.setAttribute('id', 'builderall_socialproof');
                    s.setAttribute('src', 'https://proof.builderall.com/js/show_notification.js?id=' + parseInt(socialProofConfig.id));
                    s.setAttribute('async', '');
                    s.setAttribute('defer', '');

                    document.body.appendChild(s);
                }
            }
        },

        mounted() {
          this.setPopupIfIos();
          this.callFacebookPixel(false);


            /**
             * configuração para listas do tipo ranking
             */

            let originUrl = new URL(window.location.href)
            let subscriber_id = originUrl.searchParams.get('mbsubscriber_uid');

            try{

                if(subscriber_id === null) {

                  let id = localStorage.getItem('mbsubscriber_uid');

                  if(id !== null) {
                    subscriber_id = id;
                  }

                } else {
                  localStorage.setItem('mbsubscriber_uid', subscriber_id);
                }

            }catch(e) {
              console.log(e)
            }


            var width = window.innerWidth;

            function resizePublish(force) {

            //   let widthChange = 989;
              let widthChange = 768;

              let showTablet = true;
                let viewport = document.querySelector("meta[name=viewport]");

              if(typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined') {
                if(OPTIONS_INIT_SITE.options.hasOwnProperty('tablet_version')) {
                    if(OPTIONS_INIT_SITE.options.tablet_version == 'false') {
                        showTablet = false;
                    }
                }
              }
                if ((width !== window.innerWidth || force)  ) {
                    width = window.innerWidth;

                    /**
                     * apple atualizou os safaris dos ipads pro para se comportarem como desktop e a biblioteca da publicacao nao reconhece mais
                     * a linha abaixo é para identificar isso
                     */
                    var isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
                    var largeMobileDevice = 'ontouchend' in document && width >= widthChange;

                    console.log(largeMobileDevice)
                    if (vm.isMobile || vm.isTablet || isIPadPro || largeMobileDevice ) {

                        if((vm.isTablet || isIPadPro || largeMobileDevice) && !showTablet) {

                            viewport.setAttribute('content', 'width=1366');
                            $('#editor-content').attr('data-device', 'desktop');
                            vm.device = 'desktop';

                            return;

                        } else {
                            viewport.setAttribute('content', 'width=device-width,initial-scale=1,user-scalable=no');
                            vm.device = 'mobile';
                        }
                    
                        // }

                    } 
                    else {

                        if (width <= widthChange) {
                            viewport.setAttribute('content', 'width=device-width,initial-scale=1,user-scalable=no');
                            vm.device = 'mobile';
                        } else {
                            vm.device = 'desktop';
                        }

                    }

                    if(width !== window.innerWidth) {
                      vm.fixedPanel();
                      vm.setPaddingFixedZIndex();
                    }
                }


                if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
                    vm.device = 'mobile';
                }
            }

            setTimeout(() => {
                resizePublish(true);
            }, 2000);

            window.addEventListener('resize', function () {
                resizePublish(false);
            });



            try {
                this.checkLogin(); // gera uma exceção
            }
            catch (e) {
                console.log(e)
            }

            // this.setLang();

            $('.seo-power-texts').css({'display': 'none'});

            let vm = this;

            if (typeof gdprSettings !== 'undefined' && gdprSettings !== false && gdprSettings.active) {
              try {
                var isIframeClickMap = document.referrer.includes('clickmap.builderall.com')
                if(isIframeClickMap) {
                  var scriptClickmap = document.createElement('script');
                  scriptClickmap.src = 'https://clickmap.builderall.com/js/display-clicks.js';
                  document.body.appendChild(scriptClickmap);
                }
              } catch (e) {
                console.error(e);
              }

                if (vm.getCookie('cookieControl')) {
                    vm.printScripts(['necessary']);
                    if (!this.isOnlyNecessary) {
                        vm.printScripts($.fn.ihavecookies.cookie());
                    }
                }

                if (this.$globalStoreHelpers.isValidHex(gdprSettings.backgroundColor)) {
                    gdprSettings.backgroundColor = this.$globalStoreHelpers.hexToRGBA(gdprSettings.backgroundColor);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorTitle)) {
                    gdprSettings.colorTitle = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorTitle);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorParagraph)) {
                    gdprSettings.colorParagraph = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorParagraph);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorLink)) {
                    gdprSettings.colorLink = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorLink);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonBackground)) {
                    gdprSettings.buttonBackground = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonBackground);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonColor)) {
                    gdprSettings.buttonColor = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonColor);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.onlyBtnLabel)) {
                    gdprSettings.onlyBtnLabel = this.$globalStoreHelpers.hexToRGBA(gdprSettings.onlyBtnLabel);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonAdvBackground)) {
                    gdprSettings.buttonAdvBackground = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonAdvBackground);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonAdvColor)) {
                    gdprSettings.buttonAdvColor = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonAdvColor);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonColorResetCookies)) {
                    gdprSettings.buttonColorResetCookies = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonColorResetCookies);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.buttonBackgroundResetCookies)) {
                    gdprSettings.buttonBackgroundResetCookies = this.$globalStoreHelpers.hexToRGBA(gdprSettings.buttonBackgroundResetCookies);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorValuePickerResetCookies)) {
                    gdprSettings.colorValuePickerResetCookies = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorValuePickerResetCookies);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorValuePickerResetCookies1)) {
                    gdprSettings.colorValuePickerResetCookie1 = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorValuePickerResetCookies1);
                }
                if (this.$globalStoreHelpers.isValidHex(gdprSettings.colorValuePickerResetCookies2)) {
                    gdprSettings.colorValuePickerResetCookies2 = this.$globalStoreHelpers.hexToRGBA(gdprSettings.colorValuePickerResetCookies2);
                }
                document.body.style.setProperty('--backgroundColor', gdprSettings.backgroundColor);
                document.body.style.setProperty('--colorTitle', gdprSettings.colorTitle);
                document.body.style.setProperty('--colorParagraph', gdprSettings.colorParagraph);
                document.body.style.setProperty('--colorLink', gdprSettings.colorLink);
                document.body.style.setProperty('--buttonBackground', gdprSettings.buttonBackground);
                document.body.style.setProperty('--buttonColor', gdprSettings.buttonColor);
                document.body.style.setProperty('--buttonAdvBackground', gdprSettings.buttonAdvBackground);
                document.body.style.setProperty('--buttonAdvColor', gdprSettings.buttonAdvColor);
                document.body.style.setProperty('--buttonColorResetCookies', gdprSettings.buttonColorResetCookies);
                document.body.style.setProperty('--buttonBackgroundResetCookies', gdprSettings.buttonBackgroundResetCookies);
                document.body.style.setProperty('--btnLabelResetCookies', gdprSettings.btnLabelResetCookies);
                document.body.style.setProperty('--colorValuePickerResetCookies', gdprSettings.colorValuePickerResetCookies);
                document.body.style.setProperty('--colorValuePickerResetCookies1', gdprSettings.colorValuePickerResetCookies1);
                document.body.style.setProperty('--colorValuePickerResetCookies2', gdprSettings.colorValuePickerResetCookies2);
                document.body.style.setProperty('--positionResetCookies', gdprSettings.positionResetCookies);
                document.body.style.setProperty('--onlyBtnLabel', gdprSettings.onlyBtnLabel);

                if(!!gdprSettings.fonts) {
                    document.body.style.setProperty('--messageFontFamily', gdprSettings.fonts.message.fontFamily);
                    document.body.style.setProperty('--messageFontSize', `${gdprSettings.fonts.message.fontSize}px`);

                    document.body.style.setProperty('--titleFontFamily', gdprSettings.fonts.title.fontFamily);
                    document.body.style.setProperty('--titleFontSize', `${gdprSettings.fonts.title.fontSize}px`);

                    document.body.style.setProperty('--moreInfoLabelFontFamily', gdprSettings.fonts.moreInfoLabel.fontFamily);
                    document.body.style.setProperty('--moreInfoLabelFontSize', `${gdprSettings.fonts.moreInfoLabel.fontSize}px`);

                    document.body.style.setProperty('--acceptBtnLabelFontFamily', gdprSettings.fonts.acceptBtnLabel.fontFamily);
                    document.body.style.setProperty('--acceptBtnLabelFontSize', `${gdprSettings.fonts.acceptBtnLabel.fontSize}px`);

                    document.body.style.setProperty('--onlyBtnLabelFontFamily', gdprSettings.fonts.onlyBtnLabel.fontFamily);
                    document.body.style.setProperty('--onlyBtnLabelFontSize', `${gdprSettings.fonts.onlyBtnLabel.fontSize}px`);

                    document.body.style.setProperty('--advancedBtnLabelFontFamily', gdprSettings.fonts.advancedBtnLabel.fontFamily);
                    document.body.style.setProperty('--advancedBtnLabelFontSize', `${gdprSettings.fonts.advancedBtnLabel.fontSize}px`);

                    document.body.style.setProperty('--cookieTypesTitleFontFamily', gdprSettings.fonts.cookieTypesTitle.fontFamily);
                    document.body.style.setProperty('--cookieTypesTitleFontSize', `${gdprSettings.fonts.cookieTypesTitle.fontSize}px`);
                }

                try {
                    let value = gdprSettings.borderResetCookies.split(' ');
                    let borderResetCookies = parseInt(value[0]) + 'px ' + value[1] + ' ' + value[2];

                    value = gdprSettings.borderRadiusResetCookies.split(' ');
                    let borderRadiusResetCookies = parseInt(value[0]) + 'px ' + parseInt(value[1]) + 'px ' + parseInt(value[2]) + 'px ' + parseInt(value[3]) + 'px ';

                    document.body.style.setProperty('--borderResetCookies', borderResetCookies);
                    document.body.style.setProperty('--borderRadiusResetCookies', borderRadiusResetCookies);
                } catch (e) {
                    console.log('without config');
                }

                vm.cookieTypes['preferences'].type = gdprSettings['cookieTypes.preferences.type'];
                vm.cookieTypes['preferences'].description = gdprSettings['cookieTypes.preferences.description'];

                vm.cookieTypes['analytics'].type = gdprSettings['cookieTypes.analytics.type'];
                vm.cookieTypes['analytics'].description = gdprSettings['cookieTypes.analytics.description'];

                vm.cookieTypes['marketing'].type = gdprSettings['cookieTypes.marketing.type'];
                vm.cookieTypes['marketing'].description = gdprSettings['cookieTypes.marketing.description'];

                $('.panel-constructor').ihavecookies({
                    title: gdprSettings.title,
                    message: gdprSettings.message,
                    expires: gdprSettings.expires,
                    cookieTypes: vm.cookieTypes,
                    link: gdprSettings.link,
                    moreInfoLabel: gdprSettings.moreInfoLabel,
                    acceptBtnLabel: gdprSettings.acceptBtnLabel,
                    advancedBtnLabel: gdprSettings.advancedBtnLabel,
                    uncheckBoxes: false,
                    cookieTypesTitle: gdprSettings.cookieTypesTitle,
                    fixedCookieTypeLabel: gdprSettings.fixedCookieTypeLabel,
                    fixedCookieTypeDesc: gdprSettings.fixedCookieTypeDesc,
                    onAccept: function () {
                        if (vm.getCookie('cookieControl')) {
                            if(
                                $('[element="SimpleFormComponent"].recaptcha').length > 0 ||
                                $('[element="MailingBossFormComponent"].recaptcha').length > 0
                                ) {
                                setTimeout( () => {
                                    document.location.reload(true);
                                }, 500 )
                            }
                            vm.printScripts(['necessary']);
                            if (!this.isOnlyNecessary) {
                                vm.printScripts($.fn.ihavecookies.cookie());

                                setTimeout( () => {
                                    document.location.reload(true);
                                }, 500 )
                            }
                        }

                         setTimeout( () => {
                                    document.location.reload(true);
                                }, 500 )
                    },

                });
                if (typeof gdprSettings.position === 'undefined' || gdprSettings.position === '') {
                    gdprSettings.position = 'bottom-right';
                }

                if (typeof gdprSettings.positionResetCookies === 'undefined' || gdprSettings.positionResetCookies === '') {
                    gdprSettings.positionResetCookies = 'right';
                }

                if (typeof gdprSettings.onlyBtnLabel === 'undefined' || gdprSettings.onlyBtnLabel === '') {
                    gdprSettings.onlyBtnLabel = 'Only Necessary';
                }
                setTimeout(function () {
                    $('#gdpr-cookie-message').addClass(gdprSettings.position);
                    var btnOnly = $('<button>')
                        .text(gdprSettings.onlyBtnLabel)
                        .off('click')
                        .on('click', () => {
                            this.isOnlyNecessary = true;
                            document.cookie = "cookieControlPrefs = []";
                            document.cookie = "cookieControl = true";

                            vm.printScripts($.fn.ihavecookies.cookie());

                            $('#gdpr-cookie-message').hide();
                        })
                        .attr({
                            id: "gdpr-cookie-only",
                            type: "button",

                        });
                    $("#gdpr-cookie-advanced").after(btnOnly);
                    btnOnly.show();
                    $('#gdpr-cookie-advanced').on('click', function () {
                        setTimeout(function () {
                            // $('#gdpr-cookietype-analytics').prop('checked', true);
                            // $('#gdpr-cookietype-preferences').prop('checked', true);
                            // $('#gdpr-cookietype-marketing').prop('checked', true);
                            $('[value="necessary"]').prop('disabled', false)

                            $('#gdpr-cookie-accept').text(vm.$t('ct-gdpr-accept-chosen-config'))
                        }, 1000);
                    });
                    $('#gdpr-cookie-only').on('click', function () {
                        setTimeout(function () {
                            $('#gdpr-cookietype-analytics').prop('checked', false);
                            $('#gdpr-cookietype-preferences').prop('checked', false);
                            $('#gdpr-cookietype-marketing').prop('checked', false);
                            $('#gdpr-cookie-only').attr("disabled", true);
                             document.location.reload(true);
                        }, 1000);
                    });
                }, 3000);

            } else {
                vm.printScripts(['necessary', 'marketing', 'preferences', 'analytics']);
            }


            var query = location.hash;


            var hasValue = false;
            var originalValue = 0;

            if (typeof OPTIONS_INIT_SITE !== 'undefined') {
                hasValue = true;
                originalValue = OPTIONS_INIT_SITE.options;
            }

            let searchparams = (new URL(document.location)).searchParams;


            if(searchparams.get('aid') !== null) {
                query = `#aid=${searchparams.get('aid')}`;
            }

            if (query != '') {
                if (query.indexOf('aid') >= 0) {
                    var aid = query.split('&')[0];

                    // if( location.host === 'builderall.com') {
                    //   window.location.href =  window.location.href.toLowerCase().replace('builderall.com', 'builderall-offer.com')
                    //   return;
                    // }

                    window.aid_office = aid;
                    let OPTIONS_INIT_SITE = {};
                    OPTIONS_INIT_SITE.options = {};
                    OPTIONS_INIT_SITE.options.dominio_original = window.location.host.toLocaleLowerCase();
                    OPTIONS_INIT_SITE.options.domain = window.location.host.toLocaleLowerCase();

                    if (hasValue) {
                        OPTIONS_INIT_SITE.options = originalValue
                    } else {
                        OPTIONS_INIT_SITE.options.lang = 'en_US';
                    }


                    window.OPTIONS_INIT_SITE = OPTIONS_INIT_SITE

// if (!hasValue) {


                    var date = new Date(), current_hour = date.getTime();

                    if (typeof isFunnel === 'undefined') {

                        var script = document.createElement('script');

                        script.setAttribute('src', 'https://office.builderall.com/scripts/pixel/pixel-bundle.js?v=' + current_hour);

                        document.head.appendChild(script);


                        var script2 = document.createElement('script');

                        script2.setAttribute('src', 'https://office.builderall.com/scripts/funis/complements.js?v=' + current_hour);

                        document.head.appendChild(script2);

                    }


                }
            }

            if (
                typeof OPTIONS_INIT_SITE !== 'undefined' &&
                typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                typeof OPTIONS_INIT_SITE.options.id_office_user !== 'undefined'
            ) {
                axios.get('https://www.omb11.com/get/pixel-isolated?aid=' + OPTIONS_INIT_SITE.options.id_office_user)
                    .then((res) => {
                        $("#getfranchiseScript").empty();
                        $("#getfranchiseScript").css({display: 'none'}).html(res.data);
                    })
            }

            this.$root.$on('membersAreaElearningNotApproved', () => {
                this.$refs['members_area_elearning'].open();
            });

            /*
            *  Força link de ancora na mesma pagina caso o link for somente o nome da ancora
            * */
            this.$root.$on('linkAnchorForce', () => {

                setTimeout( () => {
                    let hash = window.location.hash.substr(1);

                    if (hash !== '')
                        this.scrollToElement(hash);

                }, 30);
            });

            this.$root.$on('logoutWebsiteUser', () => {

                this.$loading.show();

                let data = {...JSON.parse(localStorage.getItem('login-user')), ...{'token': localStorage.getItem('login-user-token')}};

                if (!data.token) {
                    this.$loading.hide();
                    this.removeLogin();
                    return;
                }

                logout(data)
                    .then((res) => {
                        if (res.data.success) {
                            this.removeLogin();
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                    })
                    .finally(() => {
                        this.$loading.hide();
                    });
            });

            this.$root.$on('loginWebsiteUser', () => {

                this.actionLogin = true;
                this.$nextTick(() => {
                    this.$refs.restrictArea.changeType('login');
                });
            });

            this.$root.$on('openModalRestrictArea', ({redirect, areas, action}) => {
                this.membersArea.redirect = redirect;
                this.membersArea.open_by = 'popup';

                if (!this.auth && action !== 'logout') {
                    this.actionLogin = true;

                    this.$nextTick(() => {
                        this.$refs.restrictArea.setRedirect(redirect);
                        this.$refs.restrictArea.setAreas(areas);
                        this.$refs.restrictArea.changeType(action);

                        this.$refs.restrictArea.openModal();
                    });

                } else if (this.auth && action === 'logout') {
                    this.$root.$emit('logoutWebsiteUser');
                } else {

                    if (redirect) {
                        this.$root.$emit('linkAction', redirect);
                    } else
                        window.location.reload();
                }
            })
            ;this.$root.$on('setFixedStartPanel', (val, device) => {
                if (device && device === 'mobile')
                    this.fixedStartPanelMobile = val;
                else
                    this.fixedStartPanel = val;
            });

            this.$root.$on('linkAction', (link) => {


                /**
                 * execute pixel se houver
                 * somente facebook por enquanto, sem parametros adicionais
                 */

                try{

                    if(typeof link.pixel !== 'undefined' && link.pixel.active){

                        if(link.pixel.facebook) {
                            vm.callFacebookPixel(link.pixel)
                        }

                    }

                    if (typeof link.vote !== 'undefined' && link.vote.active) {
                        var alreadyVoted = this.$storageON() ? localStorage.getItem(`vote-system-question-${link.vote.question}`) && typeof link.vote.only_once !== 'undefined' && link.vote.only_once : true;

                        if (typeof link.vote.only_once !== 'undefined' && link.vote.only_once) {
                            if (!!alreadyVoted && typeof link.vote.url !== 'undefined' && link.vote.url !== '') {
                                return vm.$root.$emit('linkAction', {
                                    type: 'web',
                                    value: link.vote.url,
                                    target: link.target
                                });
                            }
                        }

                        if (!alreadyVoted) {
                            // axios.get(`http://api.localhost/vote-action/vote?question_code=${link.vote.question}&option=${link.vote.option}&website_id=NzkxMg==`)
                            axios.get(`/cheetah/link/vote?question_code=${link.vote.question}&option=${link.vote.option}`)
                                .then(res => {
                                    if (typeof link.vote.only_once !== 'undefined' && link.vote.only_once && this.$storageON()) {
                                        localStorage.setItem(`vote-system-question-${link.vote.question}`, 1)
                                    }
                                })
                        }

                    }

                } catch(e){
                    console.log(e)
                }

                /**
                 * tratamento para os hrefs alterados script dos funis
                 */

                if (typeof event !== 'undefined' && typeof event.target !== 'undefined' &&
                    typeof event.target.parentElement !== 'undefined' &&
                    !['popup'].includes(link.type)
                ) {
                  let element = event.target.parentElement
                  let target = event.target

                  if (target && target.tagName === 'A' && target.hasAttribute('href') && target.classList.contains('overwrite-click')) {
                    element = target
                  }

                  if (typeof element !== 'undefined' && element.classList.contains('overwrite-click')) {
                    let execute = true;

                    //se for funil
                    if (typeof OPTIONS_INIT_SITE.options.id_office_user !== 'undefined') {
                      // if (element.classList.contains('nav-link')) {
                      //   execute = false;
                      // }
                    }


                    if (execute) {
                      console.log('Overwirte click enable')
                      let href = element.getAttribute('href')

                      link.value = href;
                      link.type = 'web';

                      if (typeof link.secondaryLink !== 'undefined' && link.secondaryLink) {
                        link.secondaryLink.type = 'none';
                      }
                    }
                  }
                }


                // console.log('link');
                // console.log(link);

                if (
                    (typeof OPTIONS_INIT_SITE !== 'undefined' &&
                    !!OPTIONS_INIT_SITE.options.id_office_user && ['page', 'web'].includes(link.type) ||
                    !!window.aid_office && ['page', 'web'].includes(link.type)) &&
                    typeof link.value === 'string' && link.value.indexOf('wpp') < 0
                ) {
                    return this.redirectAid(link)
                }

                function doubleClickLink(link){
                    if(link.target === '_blank') {
                        // setTimeout(() => {
                        if (typeof link.secondaryLink !== 'undefined'&& link.secondaryLink) {

                            if(link.secondaryLink.type === 'page'){
                                vm.$root.$emit('linkAction', { type : 'page', valueID : link.secondaryLink.valueID, target: '' });
                            }else if ( link.secondaryLink.type === 'web'){
                                vm.$root.$emit('linkAction', { type : 'web', value : link.secondaryLink.value, target: '' });
                            }
                        }
                        // }, 100);
                    }
                }


                // const params = new URLSearchParams(window.location.search);
                // const queryString = '?' + params.toString();

                switch (link.type) {

                    case 'multiple' : {

                        let code = link.value.code;

                        // axios.get(`http://publication.localhost/cheetah/link/multiple?code=${code}`)
                        axios.get(`/cheetah/link/multiple?code=${code}`)
                        .then( response => {

                            if(response.data.success) {
                                let actions = JSON.parse(response.data.actions)
                                let counter = parseInt(response.data.counter)
                                let actionRedirect = actions[actions.length - 1]
                                let seted = false

                                actions.forEach( action => {
                                    // console.log(counter);
                                    if(counter <= parseInt(action.clicks) && !seted){
                                        actionRedirect = action
                                        seted = true
                                    } else {
                                        counter-= parseInt(action.clicks);
                                    }
                                } )

                                if (vm.device === 'mobile' && this.$isDeviceIOS()) {
                                    window.open(actionRedirect.action, '_self');
                                } else {
                                    window.open(actionRedirect.action);
                                }
                            }
                        } )
                        break;
                    }

                    case 'showHidePanel' : {

                        function changeTextButton(panelID, event) {

                                let buttons = document.querySelectorAll(`[panel-reference-id="${panelID}"]`)
                                let spans = [];

                                buttons.forEach( button => {
                                    let buttons_span = button.querySelectorAll('span') ;
                                    buttons_span.forEach( (inner_span) => {
                                        spans.push(inner_span);
                                    } )
                                } )
                                if(spans.length > 0){
                                    let button = document.querySelector(`[panel-reference-id="${panelID}"]`);
                                    spans.forEach( span => {
                                        span.innerHTML =  (event === 'open') ? button.getAttribute('text-show-panel') : button.getAttribute('text-hide-panel')
                                    } )

                                    var iconShow = button.getAttribute('icon-name');

                                    if (iconShow && iconShow !== '') {

                                        var iconBPosition = button.getAttribute('icon-position');

                                        if (iconBPosition === 'left') {
                                            $('<i>').addClass('mdi ' + iconShow).prependTo($(button).find('.sb-text').get(0));
                                            $('<i>').addClass('mdi ' + iconShow).prependTo($(button).find('.sb-text').get(1));
                                        } else if (iconBPosition === 'right') {
                                            $('<i>').addClass('mdi ' + iconShow).appendTo($(button).find('.sb-text').get(0));
                                            $('<i>').addClass('mdi ' + iconShow).appendTo($(button).find('.sb-text').get(1));
                                        }
                                    }
                                }
                        }


                        function _controlPanel(panelID){


                        let child = document.getElementById(panelID);
                        let panel = child.parentElement;
                        let is_button = false;


                        let spans = []; let button = '';

                        if(panel.className.indexOf('hiden-panel') !== -1){
                            $(panel).removeClass('hiden-panel');
                            $(panel).removeClass('fadeOut');
                            $(panel).addClass('fadein');
                            $(panel).addClass('showed-panel');


                             changeTextButton(panelID, 'open');


                        }else{
                            // $(panel).addClass('hiden-panel');
                            $(panel).addClass('fadeOut');
                            $(panel).removeClass('fadein');
                            $(panel).removeClass('showed-panel');


                               changeTextButton(panelID, 'close');
                            setTimeout( () => {
                                $(panel).addClass('hiden-panel');
                            }, 1000 )
                        }

                        }


                        let panelID = link.value.replace('cheetah-panel-show-hide-', '');


                        if(panelID === 'all'){

                            let panels = document.querySelectorAll('.hiden-panel');

                            if(panels.length  == 0 ) {
                                panels = document.querySelectorAll('.showed-panel');
                                changeTextButton(panelID, 'close');
                            } else {
                                changeTextButton(panelID, 'open');
                            }


                            panels.forEach( (panel) => {
                                let child = panel.querySelectorAll('[element="PanelComponent"]')[0]
                                _controlPanel(child.getAttribute('id'));
                            } )

                        } else{
                            _controlPanel(panelID);
                        }



                        break;

                    }

                    case 'email' : {
                        // if (link.value !== '' && validURL(link.target)) {
                        if (link.value !== '') {

                          if (typeof link.value !== 'object') {
                            link.value = JSON.parse(link.value)
                          }

                          const email = link.value.value
                          const emailSearchParams = new URLSearchParams({});
                          let subjectBody = ''

                          if (link.value.cc) {
                            const cc = link.value.cc !== '' ? link.value.cc : ' '
                            emailSearchParams.set('cc', cc)
                          }

                          if (link.value.bcc) {
                            const bcc = link.value.bcc !== '' ? link.value.bcc : ' '
                            emailSearchParams.set('bcc', bcc)
                          }

                          subjectBody += emailSearchParams.toString()

                          if (link.value.subject) {
                            const prefixSub = subjectBody ? '&' : ''
                            const subject = link.value.subject !== '' ? link.value.subject : ' '
                            subjectBody += `${prefixSub}subject=${subject}`
                          }

                          if (link.value.body) {
                            const prefixBody = subjectBody ? '&' : ''
                            const body = link.value.body !== '' ? link.value.body : ' '
                            subjectBody += `${prefixBody}body=${body}`
                          }

                          window.open(`mailto:${email}?${subjectBody}`, '_blank');
                        }
                        // }
                        break;
                      }

                    case 'web' : {


                        // if (link.value !== '' && validURL(link.target)) {
                        if (link.value !== '') {
                            /*
                                Link web with tags
                            */
                            let rule = /\[([^}]*?)]/g;
                            let tagResponse = rule.exec(link.value);
                            let hasTag = false;

                            if(tagResponse !== null) {
                                hasTag = true;

                            }

                            //tratamento arquivo james, ativa a função de tag advanced para segurar o clique
                            if(link.value.indexOf('file_tag')) {
                                link.tagsAdvanced = true;
                            }


                            if(link.value.indexOf(link.value.indexOf('tag_')) && hasTag) {
                                link.tagsAdvanced = true;
                                link.value = link.value.replace('tag_', '')
                            }


                            if(typeof link.tagsAdvanced !== 'undefined' && link.tagsAdvanced &&
                                typeof OPTIONS_INIT_SITE !== 'undefined' && !!OPTIONS_INIT_SITE.options.id_usuario_omb10 && hasTag ){


                                vm.tagsAdvancedLink(OPTIONS_INIT_SITE.options.id_usuario_omb10)
                                .then( response => {


                                    let newLink = link.value;

                                    Object.keys(response).forEach(function (item) {
                                        if(!!response[item]){ // diferente de null

                                            if(typeof response[item] === 'string'){

                                                newLink = newLink.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
                                                    return group.toLowerCase() in response ? encodeURI(response[group.toLowerCase()]) : match
                                                })
                                            }else if(typeof response[item] === 'object'){

                                                newLink = newLink.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
                                                    return group.toLowerCase() in response[item] ? encodeURI(response[item][group.toLowerCase()]) : match
                                                })
                                            }
                                        }
                                    });

                                    vm.$root.$emit('linkAction', {...link, tagsAdvanced: false, value: encodeURI(newLink) });
                                });
                                return;
                            }

                            //url params
                            let url_string = link.value;

                            if(url_string.indexOf('url_param_') !== -1) {

                                let url_replaced = url_string.split('?').join('parameter');
                                url_replaced = url_replaced.split('&').join('parameter');
                                let splited_url = url_replaced.split('parameter');

                                //if exists params
                                if (splited_url.length > 1) {

                                    var url_origin = new URL(window.location.href);

                                    for(let i = 1; i < splited_url.length; i++) {
                                        let paramName = splited_url[i].split('=')[1]

                                        let value = url_origin.searchParams.get(paramName.replace('url_param_', ''));

                                        if(value !== null) {
                                            url_string = url_string.replace(paramName, value)
                                        } else {
                                            url_string = url_string.replace(paramName, '')
                                        }
                                    }

                                }

                                link.value = url_string;

                            }


                            /**
                             * tratamento parametro utm
                             */

                            try {

                              let actualUrl = new URL(window.location.href)

                              // let utm_param = actualUrl.searchParams.get('utm');
                              //
                              // if(utm_param !== null && link.value.indexOf('utm') === -1) {
                              //
                              //     link.value = encodeURI((link.value.indexOf('?') === -1) ?  `${link.value}?utm=${utm_param}` :  `${link.value}&utm=${utm_param}`)
                              //     console.log(link.value)
                              //
                              // }

                              if (actualUrl.search.length > 0) {
                                var query_params = encodeURI((link.value.indexOf('?') === -1) ? `?` : `&`);
                                var count_params_utm = 0;
                                for (var pair of actualUrl.searchParams.entries()) {
                                  count_params_utm++;
                                  query_params = `${query_params}${pair[0]}=${encodeURIComponent(pair[1])}&`
                                }

                                if (count_params_utm) {
                                  if (query_params.endsWith('&')) {
                                    query_params = query_params.slice(0, -1)
                                  }

                                  link.value = `${link.value}${query_params}`;
                                }
                              }
                            } catch (e) {
                              console.log(e)
                            }

                            if (link.target === '_blank') {
                                window.open(link.value, '_blank');
                                doubleClickLink(link);
                            } else if (link.target === '_parent') {
                                window.open(link.value, '_parent');
                            } else {
                                window.location.href = link.value
                            }
                        }

                        break;
                    }
                    case 'page' : {
                        pagesConfig.forEach(page => {
                            if (link.valueID == page.id) {
                                if (link.target === '_blank') {
                                    window.open('/' + page.url, '_blank');

                                    doubleClickLink(link);
                                } else if (link.target === '_parent') {
                                    window.open('/' + page.url, '_parent');
                                } else {
                                    // console.log('aquii');
                                    window.location.href = '/' + page.url
                                }
                            }
                        });

                        break;
                    }
                    case 'anchor' : {
                        let pageAnchor = {};
                        let anchorName = '';

                        pagesConfig.forEach(page => {

                            if (typeof page !== 'undefined' && typeof page.configs !== 'undefined') {
                                page.configs.forEach(config => {
                                    if (config.key === 'anchors') {
                                        let anchors = JSON.parse(config.value);
                                        if (typeof anchors[link.valueID] !== 'undefined') {
                                            pageAnchor = page;
                                            anchorName = anchors[link.valueID];
                                        }
                                    }
                                });
                            }
                        });


                        if (pageAnchor.url !== window.location.pathname.substr(1)) {
                            if (anchorName !== '')
                                window.location.href = '/' + pageAnchor.url + '#' + anchorName;
                        } else {
                            window.location.hash = anchorName;
                            vm.scrollToElement(anchorName);
                        }

                        this.closeActivePopup();

                        break;
                    }
                    case 'newAnchor' : {
                        let pageAnchor = {};
                        let anchorName = '';

                        let linkValue = link.valueID.split('-');
                        let actualPage = currentPage.current_page;

                        pagesConfig.forEach(page => {

                            if (page !== null && page.initial === '1') {
                                actualPage = page;
                            }
                            if (typeof page !== 'undefined' && page.id == linkValue[0] && typeof page.configs !== 'undefined') {

                                page.configs.forEach(config => {
                                    if (config.key === 'anchors') {
                                        let anchors = JSON.parse(config.value);
                                        if (typeof anchors[linkValue[1]] !== 'undefined') {
                                            pageAnchor = page;
                                            anchorName = anchors[linkValue[1]];
                                        }
                                    }
                                });
                            }
                        });

                        if (typeof actualPage !== 'undefined' && window.location.pathname === '/' && actualPage.url === pageAnchor.url) {
                            window.location.hash = anchorName;
                            vm.scrollToElement(anchorName);
                        } else {

                            const isAnchorBlog = pageAnchor.is_blog === "1" && pageAnchor.is_blog === currentPage.current_page.is_blog
                            if (!isAnchorBlog && pageAnchor.url !== window.location.pathname.substr(1)) {
                                if (anchorName !== '')
                                    window.location.href = '/' + pageAnchor.url + '#' + anchorName;
                            } else {
                                window.location.hash = anchorName;
                                vm.scrollToElement(anchorName);
                            }
                        }


                        this.closeActivePopup();


                        break;
                    }

                    case 'popup' : {
                       let vm = this;


                        if (
                            typeof OPTIONS_INIT_SITE !== 'undefined' &&
                            typeof OPTIONS_INIT_SITE.options !== 'undefined' &&
                            typeof OPTIONS_INIT_SITE.options.id_office_user !== 'undefined'
                        ) {
                            var date = new Date(),
                                current_hour = date.getTime();

                            var script = document.createElement('script');

                            script.setAttribute('src', 'https://office.builderall.com/scripts/pixel/pixel-bundle.js?v=' + current_hour);

                            document.head.appendChild(script);
                        }


                        function getUrlWithLogin(url){
                            let loginPasswordMD5 = !!localStorage.getItem('login-user-token-md5');
                            let loginUser = !!localStorage.getItem('login-user') && this.$isJson(localStorage.getItem('login-user'));

                            if (loginPasswordMD5 && loginUser) {
                                var user = JSON.parse(localStorage.getItem('login-user'));
                                var passwordMD5 = localStorage.getItem('login-user-token-md5');
                                let email = (typeof user.email !== 'undefined') ? user.email : false;

                                return url + (url.slice(-1) !== '/' ? '/' : '') + (url.indexOf('index') > 0 ? '' : 'index') + '?login=' + (email) + '&password=' + passwordMD5;
                            }
                            else return url;
                        }

                        function hasElearning(contentIframe) {
                            try {
                                let loginPasswordMD5 = !!localStorage.getItem('login-user-token-md5');
                                let loginUser = !!localStorage.getItem('login-user') && this.$isJson(localStorage.getItem('login-user'));
                                var urlMatch = contentIframe.match(/src="(.*?)"/);

                                if (loginPasswordMD5 && loginUser && !!urlMatch && typeof urlMatch[1] !== 'undefined') {
                                    let url = urlMatch[1];
                                    let urlAuth = getUrlWithLogin(url);

                                    return contentIframe.split(url).join(urlAuth);
                                } else
                                    return contentIframe;
                            } catch (e) {
                                console.log('error elearning iframe', e);
                            }
                        }
                        /* função que força o carregamento do iframe novamente na popup */
                        function reloadIframe(popup) {
                            try {
                                let iframes = popup.$el.querySelectorAll('[element="IframeComponent"]');
                                iframes.forEach((elemt) => {
                                    let id = elemt.id;
                                    let insideIframe = elemt.querySelector('iframe');
                                    let bodyHTML = insideIframe.contentWindow.document.body.innerHTML;
                                    let isEmpty = (bodyHTML.trim) ? bodyHTML.trim() : bodyHTML.replace(/^\s+/, '');

                                    let contentIframeStorage = (vm.$storageON()) ? localStorage.getItem('iframe-content-' + id) : false;

                                    if (isEmpty.length === 0) {

                                        let isEmptyStorage = (!!contentIframeStorage) ?
                                            ((contentIframeStorage.trim) ? contentIframeStorage.trim() : contentIframeStorage.replace(/^\s+/, '')) : '';
                                        if (!!contentIframeStorage && isEmptyStorage.length > 0) {
                                            let doc = insideIframe.contentWindow.document;

                                            if (contentIframeStorage.indexOf('src="https://elearning.eb4us.com/course/') > 0 || contentIframeStorage.indexOf('src="https://elearning.builderall.com/course/') > 0) {
                                                contentIframeStorage = hasElearning(contentIframeStorage);
                                            }

                                            doc.open();
                                            doc.write(contentIframeStorage);
                                            doc.close();
                                        } else {
                                            let contentIframe;
                                            axios.get('/get_iframe_content?iframe_id=' + id)
                                                .then(res => {
                                                    contentIframe = res.data.content;

                                                    if (contentIframe && vm.$storageON())
                                                        localStorage.setItem('iframe-content-' + id, contentIframe);

                                                    if (contentIframe.indexOf('src="https://elearning.eb4us.com/course/') > 0 || contentIframeStorage.indexOf('src="https://elearning.builderall.com/course/') > 0) {
                                                        contentIframe = hasElearning(contentIframe);
                                                    }

                                                    let doc = insideIframe.contentWindow.document;
                                                    doc.open();
                                                    doc.write(contentIframe);
                                                    doc.close();

                                                })
                                        }
                                    }
                                });

                            } catch (e) {
                                console.log('error reload iframe', e);
                            }
                        }

                        function img3DPopup(popup) {
                            //tratamento para lidar com a imagem3D em popup
                            try {
                               let image3D = popup.$el.querySelectorAll('[element="Image3DComponent"]');
                               $.each(image3D, function(index, value) {
                               let image = image3D.querySelector('img'); //.querySelectorAll('img')

                                        if(!$(image).hasClass('image3D')){
                                            let imageClone = $(image).clone();

                                            $(image3D[index]).empty()
                                            $(imageClone).appendTo(image3D[index])
                                            $(imageClone).addClass('image3D');
                                            $(imageClone).css('height','auto');
                                            $(imageClone).css('width',image3D[index].style.width);
                                            // console.log($(image3D).widht, "oe")
                                            // console.log($(image),"deus")
                                            $(image).hasClass('image3D')

                                                setTimeout (function (){
                                                    // $(imageClone).atvImg();
                                                },2000);

                                        }
                                })


                            } catch (e) {2
                                console.log('error image3D', e);
                            }
                        }

                        //check opened popups
                        let elements = document.querySelectorAll('.sweet-modal-overlay.is-visible');

                        elements.forEach( popup => {
                            vm.$refs[popup.getAttribute('id')][0].close();
                        } )



                        this.userPopups.forEach(popup => {
                            if (parseInt(link.valueID) === parseInt(popup.id)) {


                               this.popupOverlayStyle(popup);


                                vm.popupStyle = '';
                                reloadIframe(vm.$refs['popup' + popup.id][0]);
                                vm.$refs['popup' + popup.id][0].open();
                            }
                        })

                        //  try {
                        //
                        //
                        //     setTimeout(()=> {
                        //         document.querySelectorAll('.sweet-content-content a:not(.sb-text)').forEach( (target) => {
                        //
                        //             console.log('rodou')
                        //
                        //             try{
                        //                 let link = {
                        //                 type: target.attributes.type.value,
                        //                 value: target.attributes.value.value,
                        //                 valueID:  target.attributes.valueID.value,
                        //                 target:  target.attributes.target.value,
                        //                 tel:  target.attributes.tel.value,
                        //                 telSelectedMask:  target.attributes.telSelectedMask.value,
                        //                 telOpenAs:  target.attributes.telOpenAs.value,
                        //                 secondaryLink: (typeof target.attributes.secondaryLink.value !== 'undefined') ? JSON.parse(target.attributes.secondaryLink.value) : false ,
                        //                 }
                        //
                        //
                        //                 $(target).off('click').on('click', () => {
                        //                     document.getElementById('app').__vue__.callLinkAction(link)
                        //                 })
                        //
                        //                 }catch(e) {
                        //                     console.log(e)
                        //                 }
                        //
                        //         } )
                        //     }, 4000 )
                        //
                        // } catch (error) {
                        //     console.log(error)
                        // }
                        break;
                    }
                    case 'video' : {
                        if (link.value !== '') {
                            this.$refs.videoModalIframe.src = link.value;
                            $(this.$refs.videoModal).modal()


                            $(this.$refs.videoModal).click(event => {
                                const { target } = event

                                if(target.className.indexOf('cheetah-modal-video') !== -1) {
                                    this.yourHandlerForCloseHere()
                                }
                            })
                        }

                        break;
                    }

                    case 'supercheckout': {
                        window.open(link.value, "_blank");
                        break;
                    }

                    case 'slider': {

                        this.$root.$emit('showSlider', link.value);

                        break;
                    }

                    case 'telefone': {
                        const {tel, telOpenAs} = link

                        if (telOpenAs === 'call' || typeof telOpenAs === 'undefined')
                            document.location.href = `tel:${tel}`;

                        if (telOpenAs === 'sms')
                            document.location.href = `sms:${tel}`;

                        if (telOpenAs === 'whatsapp')
                            document.location.href = `https://api.whatsapp.com/send?phone=${tel}`
                        break;
                    }

                    case 'download' : {
                        if (link.value !== '') {
                            let url = link.value;

                            var linkBtn = document.createElement("a");
                            linkBtn.download = 'file';
                            linkBtn.target = '_blank';
                            linkBtn.href = url;
                            linkBtn.click();

                            console.log('url', url);


                            // axios.get(url, {
                            // 	responseType: "blob"
                            // })
                            // 	.then(response => {
                            //
                            // 		const urlData = window.URL.createObjectURL(new Blob([response.data]));
                            // 		const linkA = document.createElement('a');
                            // 		linkA.href = urlData;
                            // 		linkA.setAttribute('download', link.value.name + '.' + link.value.src.split('.').pop()); //or any other extension
                            // 		document.body.appendChild(linkA);
                            // 		linkA.click();
                            //
                            // 	})
                            // 	.catch(err => {
                            // 		var linkBtn = document.createElement("a");
                            // 		linkBtn.download = link.value.name;
                            // 		linkBtn.target = '_blank';
                            // 		linkBtn.href = url;
                            // 		linkBtn.click();
                            // 	});
                        }
                        break;
                    }

                    case 'restrict':
                        this.setAuth();
                        this.membersArea.redirect = link.value.redirect;
                        this.membersArea.open_by = 'popup';

                        if (!this.auth && link.value.action !== 'logout') {
                            this.actionLogin = true;

                            this.$nextTick(() => {
                                this.$refs.restrictArea.setRedirect(link.value.redirect);
                                this.$refs.restrictArea.setAreas(link.value.areas);
                                this.$refs.restrictArea.setElearningRedirect(link.value);
                                this.$refs.restrictArea.changeType(link.value.action);

                                this.$refs.restrictArea.openModal();
                            });

                        } else if (this.auth && link.value.action === 'logout') {
                            this.$root.$emit('logoutWebsiteUser');
                        } else {

                            if (link.value.areas.length > 0 && !!(typeof link.value.redirect_elearning_course !== 'undefined' && parseInt(link.value.redirect_elearning_course))) {
                                let members_area_active = restrictsArea.filter(restrict => link.value.areas.includes(parseInt(restrict.id)));
                                let restrictArea = members_area_active.filter(areas => areas.elearning_course_id);

                                if(restrictArea)
                                    this.callRedirectElearning(restrictArea, 'popup');
                                else {
                                    pagesConfig.forEach(page => {
                                        if (+link.value.redirect === +page.id) {
                                            window.location.href = '/' + page.url
                                        }
                                    });
                                }
                            } else if (link.value.redirect && !!parseInt(link.value.redirect)) {
                                pagesConfig.forEach(page => {
                                    if (+link.value.redirect === +page.id) {
                                        window.location.href = '/' + page.url
                                    }
                                });
                            } else
                                window.location.reload();


                        }

                        break;

                    case 'restrictIntegration':

                        try {
                            var integration_id = link.value.integration_id;
                            var integration = restrictsArea.map(restrict => restrict.integrations.find(integration => integration.id === String(integration_id)))
                                .find(integration => integration.id === String(integration_id));

                            let url = integration.url_checkout;
                            let matchUrl = url.match('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')

                            if (url && matchUrl) {
                                if (link.target === '_blank') {
                                    window.open(url, '_blank');
                                } else if (link.target === '_parent') {
                                    window.open(url, '_parent');
                                } else {
                                    window.location.href = url
                                }
                            }
                        } catch (e) {
                            console.warn(e);
                        }
                        break;

                    case 'browserNotification':
                        try {
                            let permission = Notification.permission;
                            let vm = this;
                            this.bnStatus = permission;

                            if(typeof bnSettings !== 'undefined' && bnSettings) {
                                let s = document.createElement('script');
                                s.setAttribute('id', 'notifyEb4usScript');
                                s.setAttribute('src', 'https://notify.eb4us.com/js/pushNotify.js');
                                s.setAttribute('data-campaign', bnSettings.project_id);

                                document.body.appendChild(s);
                            }

                            if(this.bnStatus && this.bnStatus !== 'default'){
                                this.$refs['browser-notification-alert'].open();
                            }
                        } catch (e) {
                            console.warn(e);
                        }
                        break;
                    case 'copy': {
                        if (link.value !== '') {
                            try {
                                var copySuccess = false;
                                var dateNow = new Date().getTime();
                                let body = document.getElementsByTagName('body');
                                let toCopy = document.createElement('input');

                                toCopy.setAttribute('type', 'text');
                                toCopy.setAttribute('id', `link-copy-${dateNow}`);
                                toCopy.setAttribute('value', link.value);
                                body[0].append(toCopy);
                                toCopy.select();

                                copySuccess = !!document.execCommand("copy");
                                toCopy.setAttribute("type", "hidden");
                                window.getSelection().removeAllRanges();

                                if(typeof link.element_id !== 'undefined') {
                                    var $subText = $('#' + link.element_id).find('.sb-text');
                                    $subText.append('<i id="copy-link-check" class="fa fa-check ml-1"></i>')
                                    $subText.find('#copy-link-check').fadeOut(500).fadeIn(500);

                                    setTimeout(() => {
                                        $subText.find('#copy-link-check').remove();
                                    }, 1000);
                                }
                            } catch (err) {
                            }
                        }
                        break;
                    }
                }




            });

            this.$root.$on('errorMessagePopup', (errors) => {
                this.errorMessage = errors;
                this.$refs.required_fields.open();
            });
            this.$root.$on('successMessagePopup', (message) => {
                this.successSendMessage = message;
                this.$refs.success_send.open();
            });

            //Show image url
            this.$root.$on('galleryShowImageUrl', (open, url) => {
                this.galleryImage.overlay = open;
                this.galleryImage.image = url;
            });

            // Pra lembrar* - O css transform quebra o sticky
            this.$root.$on('applySticky', (element) => {
                if (this.device == "desktop") {

                    var $window = $('.panel-constructor');
                    var $videoWrap = $('.sticky-video-content');
                    var $video = $('.sticky-video-content .video');
                    var videoHeight = document.getElementById('the-sticky-div').clientHeight;
                    var videoWidth = document.getElementById('the-sticky-div').clientWidth;
                    var $elementVideo = $(element.$el);
                    var videoBottom = videoHeight + $elementVideo.offset().top;
                    // console.log($elementVideo, "quem sou eu")

                    $window.on('scroll', function () {
                        var windowScrollTop = document.querySelector('#app').scrollTop;

                        if (windowScrollTop > videoBottom) {

                            if (element.internal_config.sizeSticky == "small") {
                                switch (element.internal_config.positionSticky) {
                                    case "top-left":
                                        $elementVideo.addClass('stuckTLs');
                                        break;
                                    case "top-right":
                                        $elementVideo.addClass('stuckTRs');
                                        break;
                                    case "bottom-left":
                                        $elementVideo.addClass('stuckBLs');
                                        break;
                                    case "bottom-right":
                                        $elementVideo.addClass('stuckBRs');
                                        break;
                                }
                            } else if (element.internal_config.sizeSticky == "medium") {
                                switch (element.internal_config.positionSticky) {
                                    case "top-left":
                                        $elementVideo.addClass('stuckTLm');
                                        break;
                                    case "top-right":
                                        $elementVideo.addClass('stuckTRm');
                                        break;
                                    case "bottom-left":
                                        $elementVideo.addClass('stuckBLm');
                                        break;
                                    case "bottom-right":
                                        $elementVideo.addClass('stuckBRm');
                                        break;
                                }
                            } else {
                                switch (element.internal_config.positionSticky) {
                                    case "top-left":
                                        $elementVideo.addClass('stuckTLl');
                                        break;
                                    case "top-right":
                                        $elementVideo.addClass('stuckTRl');
                                        break;
                                    case "bottom-left":
                                        $elementVideo.addClass('stuckBLl');
                                        break;
                                    case "bottom-right":
                                        $elementVideo.addClass('stuckBRl');
                                        break;
                                }
                            }
                        } else {
                            $elementVideo.height('auto');
                            // $$elementVideo.width('auto');
                            $elementVideo.removeClass('stuckTRs');
                            $elementVideo.removeClass('stuckTLs');
                            $elementVideo.removeClass('stuckBRs');
                            $elementVideo.removeClass('stuckBLs');
                            $elementVideo.removeClass('stuckTRm');
                            $elementVideo.removeClass('stuckTLm');
                            $elementVideo.removeClass('stuckBRm');
                            $elementVideo.removeClass('stuckBLm');
                            $elementVideo.removeClass('stuckTRl');
                            $elementVideo.removeClass('stuckTLl');
                            $elementVideo.removeClass('stuckBRl');
                            $elementVideo.removeClass('stuckBLl');
                        }
                    });
                }
            });


            //PinElement
            this.$root.$on('applyPin', (element) => {
                let elem = element.$el;
                if (element.internal_config.pinConfig.manual) {

                    $(elem).css({
                        position: element.internal_config.pinConfig.position,
                        top: element.internal_config.pinConfig.pTop,
                        left: element.internal_config.pinConfig.pLeft,
                        margin: 0,
                        width: 0,
                        height: 0,
                    })
                } else {
                    let elem = element.$el;
                    // var elemento = document.getElementById(this.element.id);
                    let _pai = document.getElementById('editor-content')
                    let _widthpai = _pai.offsetWidth;
                    let _heightpai = window.innerHeight;
                    let _toppai = ~~$(_pai).offset().top;
                    let _leftpai = ~~$(_pai).offset().left;
                    let _topElem = ~~$(elem).offset().top;
                    let _leftElem = ~~$(elem).offset().left;
                    let _widthfilho = $(elem).children().width();
                    let _heightfilho = $(elem).children().height();

                    switch (element.internal_config.pinConfig.type) {
                        case "top-left":

                            //Configura css
                            $(elem).css({
                                position: "fixed",
                                left: `${_leftpai}px`,
                                top: 0,
                                margin: 0,
                                width: 0,
                                height: 0,
                            });
                            break;

                        case "top-mid":

                            $(elem).css({
                                position: "fixed",
                                top: 0,
                                left: `${_widthpai / 2 - _widthfilho / 2}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });

                            break;

                        case "top-right":

                            $(elem).css({
                                position: "fixed",
                                top: 0,
                                left: `${_widthpai - _widthfilho}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });

                            break;

                        case "mid-mid":

                            $(elem).css({
                                position: "fixed",
                                top: _heightpai / 2 - _heightfilho / 2,
                                left: `${_widthpai / 2 - _widthfilho / 2}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });

                            break;

                        case "mid-left":
                            $(elem).css({
                                position: "fixed",
                                top: _heightpai / 2 - _heightfilho / 2,
                                left: _leftpai,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });

                            break;

                        case "mid-right":

                            $(elem).css({
                                position: "fixed",
                                top: `${_heightpai / 2 - _heightfilho / 2}px`,
                                left: `${_widthpai - _widthfilho}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });
                            break;

                        case "bottom-left":

                            $(elem).css({
                                position: "fixed",
                                top: _heightpai - _heightfilho,
                                left: _leftpai,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });

                            break;

                        case "bottom-mid":
                            $(elem).css({
                                position: "fixed",
                                top: _heightpai - _heightfilho,
                                left: `${_widthpai / 2 - _widthfilho / 2}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });
                            break;

                        case "bottom-right":

                            $(elem).css({
                                position: "fixed",
                                top: _heightpai - _heightfilho,
                                left: `${_widthpai - _widthfilho}px`,
                                right: "",
                                margin: 0,
                                width: 0,
                                height: 0,
                            });
                            break;
                    }
                }
            });

            this.$root.$on('applyAnimation', (vm, defaultClass) => {

//clean and restore default classes
                vm.$el.className = '';
                $(vm.$el).off(".animation");
                $(vm.$el).addClass(defaultClass);

//prep;
                let animation = vm.internal_config.animation.in;
                if (animation.effect === 'none') {
                  return;
                }
                if (animation.effect.indexOf('In') !== -1 && ['time', 'scroll'].includes(animation.action)) {
                    vm.$el.classList.add('invisible');
                }


//apply
                let infinite = animation.infinite === true ? vm.$el.classList.add('infinite') : '';
                vm.$el.classList.add(animation.speed);



                //setando tempo de execusão da animação para as animações de saida
                let timeAnimation = 0;

                switch(animation.speed){
                    case 'slow':
                        timeAnimation = 1950
                        break;
                    case 'slower':
                        timeAnimation = 2950
                        break;
                    case 'fast':
                        timeAnimation = 750
                        break;
                    case 'faster':
                        timeAnimation = 450
                        break;
                }

//apply time
                switch (animation.action) {
                    case 'time':
                        setTimeout(function () {
                            vm.$el.classList.remove('invisible');
                            vm.$el.classList.add(animation.effect, 'animated');
                            animation.infinite === true ? vm.$el.classList.add('infinite') : '';
                        }, animation.time * 1000);
                        break;
                    case 'click':
                        if(animation.effect.indexOf("Out") != -1){
                            $(vm.$el).on('click.animation', () => {
                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');

                                setTimeout(function(){
                                    vm.$el.classList.add('displayClass');
                                    $('.displayClass').css('display','none');
                                },timeAnimation);

                                if (!infinite) {
                                    vm.$el.addEventListener('animationend', function () {
                                        vm.$root.$emit('applyAnimation', vm, defaultClass);
                                    });
                                }
                            });

                        }else{
                            $(vm.$el).on('click.animation', () => {
                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');
                                if (!infinite) {
                                    vm.$el.addEventListener('animationend', function () {
                                        vm.$root.$emit('applyAnimation', vm, defaultClass);
                                    });
                                }
                            });
                        }
                        break;
                    case 'hover':
                        if(animation.effect.indexOf("Out") != -1){
                            $(vm.$el).on('mouseover.animation', () => {

                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');

                                setTimeout(function(){
                                    vm.$el.classList.add('displayClass');
                                    $('.displayClass').css('display','none');
                                },timeAnimation);

                                if (!infinite) {
                                    vm.$el.addEventListener('animationend', function () {
                                        vm.$root.$emit('applyAnimation', vm, defaultClass);
                                    });
                                }
                            });
                        }else{
                            $(vm.$el).on('mouseover.animation', () => {
                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');
                                if (!infinite) {
                                    vm.$el.addEventListener('animationend', function () {
                                        vm.$root.$emit('applyAnimation', vm, defaultClass);
                                    });
                                }
                            });
                        }
                        break;
                    case 'scroll':

                    /*function getAllTopsParents(element, more = 0) {

                        // tratamento para pegar todos o top quando o elemento for filho de outro elemento e do painel
                        function findAncestor(el, sel) {
                            while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel))) ;
                            return el;
                        }

                        var parent = findAncestor(element, '.sitebuilder-element-container');

                        if (parent === null) {
                            var panel = findAncestor(element, '[element="PanelComponent"]').getBoundingClientRect().top;
                            var elemRect = element.offsetTop;

                            return panel + elemRect + more;
                        } else {
                            return getAllTopsParents(parent, element.offsetTop);
                        }
                    }*/

                        function getOffsetTopOnApp(id) {
                            return document.getElementById(id).getBoundingClientRect().top + document.getElementById('app').scrollTop;
                        }

                        let element = document.getElementById(vm.$el.id),
                            bodyRect = document.body.getBoundingClientRect(),
                            elemRect = element.getBoundingClientRect();

                        if (window.innerHeight >= getOffsetTopOnApp(vm.$el.id)) {
                            window.addEventListener('load', function () {
                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');
                            });
                        }

                        document.addEventListener('scroll', function () {
                            if (document.getElementById('app').scrollTop + window.innerHeight >= getOffsetTopOnApp(vm.$el.id)) {
                                // console.log('aplicou');
                                vm.$el.classList.remove('invisible');
                                vm.$el.classList.add(animation.effect, 'animated');
                            }
                        }, true /*Capture event*/);

                        break;
                }
            })
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            if (typeof config === 'undefined' || config === '') {
                axios.get(window.location.origin = '/cheetah/version')
                    .then((res => {
                        if (res.data === 'published') {
                            location.reload();
                        }
                    }))
            }

            try{

                let hash = window.location.hash.substr(1);
                let hasAnchor = hash !== '';

                if(isMobile && !hasAnchor && config.length >= 15 && !['33782','6403426','6808149', '6994162', '41678', '6343968', '6495266', '6976019', '6836553', '6664201', '18801'].includes(window.OPTIONS_INIT_SITE.options.id_usuario_omb10)) {
                    console.log('paineis timeout');
                    config.forEach( (panel, idx) => {
                        setTimeout( () => {
                            this.elements.push(panel)
                            urlParamParse.parse(500)
                        } ,  idx * 500)
                    } )

                } else {
                    this.elements = config;
                }

            }catch(e) {
               this.elements = config;
            }


            // if (typeof popupConst !== 'undefined') {
            //     this.userPopups = popupConst;
            // }
            // this.fontGroup = fontConfig;

// this.popupKeys = Object.keys(popupConst);

            this.applyFontGroup();

            if (window.location.pathname === '/') {

                let actualPage;

                try {
                    pagesConfig.forEach((page) => {

                        if (page !== null && page.initial === '1') {
                            actualPage = page;
                        }
                    })
                } catch (e) {

                }

                if (typeof actualPage !== 'undefined' && typeof actualPage.configs !== 'undefined') {

                    this.setMbossReference(actualPage);
                    let pageConfig = actualPage.configs;
                    Object.keys(pageConfig).forEach((idx) => {
                        let config = pageConfig[idx];


                        if (config.key === "bgPropsPage") {
                            this.bgPropsPage = JSON.parse(config.value)
                            this.applyBackground();
                        }
                        if (config.key === "tagPage") {
                            this.tagPage = JSON.parse(config.value)
                            this.applyTags();
                        }
                        if (config.key === "popupSettings") {
                            this.popupSettings = JSON.parse(config.value)
                            this.applyPopups();
                        }
                    })

                }

            } else {

                var url = window.location.pathname.replace('/', '');

                if (url.slice(-1) === '/') {
                    url = url.slice(0, -1);
                }

                if (url.indexOf('page/') !== -1) {
                    url = 'page';
                }

                if (url.indexOf('blog/') !== -1) {
                  var slit = url.split('/');

                  if(slit.length >= 2 && slit[0] === 'blog') {
                    url = slit[1]
                  }
                }

                var pageConfig = false;
                try {
                    pagesConfig.forEach(page => {
                        if (page.url.toLowerCase() == url.toLowerCase()) {
                            pageConfig = page;
                        }
                    });

//find 404 page
                    if (!pageConfig) {
                        pagesConfig.forEach(page => {
                            if (page.url == '404') {
                                pageConfig = page;
                            }
                        });
                    }

                    if (!pageConfig) {
                        pagesConfig.forEach(page => {
                            if (page !== null && page.initial === '1') {
                                pageConfig = page;
                            }
                        });
                    }

                } catch (e) {

                }

                if (typeof pageConfig !== 'undefined' && pageConfig !== false && pageConfig.configs !== null) {

                    this.setMbossReference(pageConfig);

                    pageConfig.configs.forEach((config) => {

                        if (config.key === "bgPropsPage") {
                            this.bgPropsPage = JSON.parse(config.value);
                            this.applyBackground();

                        }
                        if (config.key === "tagPage") {
                            this.tagPage = JSON.parse(config.value);
                            this.applyTags();

                        }
                        if (config.key === "popupSettings") {
                            this.popupSettings = JSON.parse(config.value);
                            this.applyPopups();
                        }
                    })

                }

            }

            this.applyBackground();

            this.$nextTick(() => {

                try {

                    let allFonts = document.querySelectorAll('font')

                    for (var i = 0, max = allFonts.length; i < max; i++) {
                        allFonts[i].style['-webkit-text-fill-color'] = allFonts[i].getAttribute('color');
                    }
                    let all = document.querySelectorAll('[element="TextComponent"] span');

                    for (var i = 0, max = all.length; i < max; i++) {
                        all[i].style['-webkit-text-fill-color'] = all[i].style.color;
                    }
                    all.forEach((el) => {
                        if (el.style.lineHeight != '') {
                            el.classList.add('line-height-aqui');
                        }
                    })
                    if (/Edge/.test(navigator.userAgent)) {
                        $('body').addClass('shit-ie');
                    }

                    // var firstPanelDesktop = $('.sitebuilder-element-panel:not(.hide-on-desktop)');
                    // var firstPanelMobile = $('.sitebuilder-element-panel:not(.hide-on-mobile)');
                    // console.log('device', this.device);
                    // if ($('[data-fixed="true"]').length > 0) {
                    //     firstPanelDesktop.get(1).style.marginTop = vm.fixedStartPanel;
                    // } else {
                    //     firstPanelDesktop.addClass('remove-margin-top');
                    // }
                    //
                    // if ($('[data-fixed-mobile="true"]').length > 0) {
                    //     firstPanelMobile.get(1).style.marginTop = vm.fixedStartPanel;
                    // } else {
                    //     firstPanelMobile.addClass('remove-margin-top-mobile')
                    // }
                } catch (e) {

                }

                $('.panel-constructor').removeClass('invisible');
                this.setAuth();
                // this.setBannerFree();
                this.setWaterMark();
                this.replaceLinkWithTags();

				this.setSplitTestData();

                let hash = window.location.hash.substr(1);

                if (hash !== '') {
                    vm.scrollToElement(hash);
                }
            })

            urlParamParse.parse(500)

            axios.get('/cheetah/version')
                .then(res => {
                    // console.log(res);
                });

          let supercheckoutAffiliation = searchparams.get('sc-aid');
          if (supercheckoutAffiliation) {
            let scPixel = document.createElement('img')
            let supercheckoutApiUrl = apiUrl()
            scPixel.src = supercheckoutApiUrl + '/affiliates/pixel/' + supercheckoutAffiliation
            scPixel.style.display = 'none'
            document.body.appendChild(scPixel)
          }

          this.setPaddingFixedZIndex();

          var $containerScroll = $('.editor-content-header-modify-on-scroll');
          var $header = $('[data-header-modify-on-scroll="init"]');
          var $headerSecondary = $('[data-header-modify-on-scroll="on-scroll"]');

          if($headerSecondary.length > 0) {
            $('#app').on("scroll touchmove", function () {
              setTimeout(() => {
                var $nav_height = $header.outerHeight(true);
                var $headerSecondary = $('[data-header-modify-on-scroll="on-scroll"]');

                if ($(this).scrollTop() > $nav_height) { //if scrolling is equal to or greater than the nav height add a class
                  vm.showHeaderSecondary = true;
                  $('[data-header-modify-on-scroll="init"]').removeClass('is-showed');
                  $headerSecondary.addClass('is-showed');
                  vm.$root.$emit('setFixedStartPanel', $headerSecondary.height(), vm.device);
                } else { //is back at the top again, remove the class
                  setTimeout(() => {
                    $headerSecondary.removeClass('is-showed');
                    $('[data-header-modify-on-scroll="init"]').addClass('is-showed');
                  }, 200);
                  vm.$root.$emit('setFixedStartPanel', $header.height(), vm.device);
                  vm.showHeaderSecondary = false;
                }
              }, 200);
            });
          }

          this.$nextTick(() => {
            $containerScroll.addClass('active');
          });
        }
    }
</script>

<style lang="scss" scoped>
    .panel-constructor.invisible-bg {
        background-color: #ffffff00 !important;
        visibility: visible !important;
    }

    .panel-constructor:not(.invisible-bg) .is-android-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        height: 100vh;
        clip: rect(0, auto, auto, 0);
        background: var(--bg-page);
    }
</style>
<style lang="scss">

    @import "./assets/sass/fonts.scss";
    @import "./assets/scss/ihover.scss";

    .editor-content-header-modify-on-scroll {
      display: none;
      position: sticky;
      top: 0;
      z-index: 9000;

      &.active {
        display: block;
      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity .5s ease-in-out;
      }

      .fade-enter, .fade-leave-to {
        opacity: 0;
      }

      .editor-content-layout-item, .panel {
        transition: all .5s ease-in-out;
      }

      [data-header-modify-on-scroll]:not(.is-showed) .panel {
        min-height: 0 !important;
        height: 0 !important;
      }
    }
    .sweet-action-close:hover {
        background: #000 !important;
        color: #fff !important;
    }

    html {
        overflow: hidden;
    }

    body.free-website {
        #editor-content {
            margin-top: 50px;
        }

        .sweet-modal-overlay {
            top: 50px !important;
        }
    }

    #app {
        -webkit-font-smoothing: antialiased; // faz com que as fontes ficam igual em todos navegadores mac x janelas
        -moz-osx-font-smoothing: grayscale;
    }

    html[lang="he"], html[lang="ar"] {
        [element="SimpleFormComponent"] .form-group, [element="MailingBossFormComponent"] .form-group {
            text-align: right;
        }
    }

    @for $i from 20 through 100 {
        $class: '[data-device="desktop"] .max-height';

        $class: $class + '-' +$i;

        #{$class} {
            max-height: #{$i}vh !important;

            .sweet-modal {
                height: 100% !important;
                background: transparent !important;
            }
        }
    }

    [data-device="desktop"] {

        .sweet-modal .panel-body.container {
            width: 990px !important;
        }

        .sweet-modal-overlay {
            &.overlay-none {
                background: transparent;
            }

            &.full-size {
                .sweet-modal {
                    max-width: 100% !important;
                }
            }

            &.direction-bottom {
                bottom: 0;
                top: auto;
            }
        }
    }

    .padding-modal .sweet-content {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    [element$="VideoComponent"] {
        .the-sticky-div-content {
            position: relative;
            display: block;
            width: 100%;
            padding: 0;
            overflow: hidden;
        }

        /*Small*/
        &.stuckBRs, &.stuck-bottom-right-small {
            position: fixed;
            transform: translateY(100%);
            width: 260px !important;
            height: 145px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }

        &.stuckTLs, &.stuck-top-left-small {
            position: fixed;
            transform: translateY(100%);
            width: 260px !important;
            height: 145px !important;
            animation: fade-in-up .25s ease forwards;

            left: 10px !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckTRs, &.stuck-top-right {
            position: fixed;
            transform: translateY(100%);
            width: 260px !important;
            height: 145px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckBLs, &.stuck-bottom-left {
            position: fixed;
            transform: translateY(100%);
            width: 260px !important;
            height: 145px !important;
            animation: fade-in-up .25s ease forwards;

            right: unset;
            left: 10px !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }

        /*Medium*/
        &.stuckBRm, &.stuck-bottom-right-medium {
            position: fixed;
            transform: translateY(100%);
            width: 400px !important;
            height: 227px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }

        &.stuckTLm, &.stuck-top-left-medium {
            position: fixed;
            transform: translateY(100%);
            width: 400px !important;
            height: 227px !important;
            animation: fade-in-up .25s ease forwards;

            left: 10px !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckTRm, &.stuck-top-right-medium {
            position: fixed;
            transform: translateY(100%);
            width: 400px !important;
            height: 227px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckBLm, &.stuck-bottom-left {
            position: fixed;
            transform: translateY(100%);
            width: 400px !important;
            height: 227px !important;
            animation: fade-in-up .25s ease forwards;

            right: unset;
            left: 10px !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }

        /*Large*/
        &.stuckBRl, &.stuck-bottom-right {
            position: fixed;
            transform: translateY(100%);
            width: 500px !important;
            height: 283px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }

        &.stuckTLl, &.stuck-top-left {
            position: fixed;
            transform: translateY(100%);
            width: 500px !important;
            height: 283px !important;
            animation: fade-in-up .25s ease forwards;

            left: 10px !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckTRl, &.stuck-top-right {
            position: fixed;
            transform: translateY(100%);
            width: 500px !important;
            height: 283px !important;
            animation: fade-in-up .25s ease forwards;

            right: 25px;
            left: unset !important;
            top: 10px !important;
            z-index: 100 !important;
        }

        &.stuckBLl, &.stuck-bottom-left {
            position: fixed;
            transform: translateY(100%);
            width: 500px !important;
            height: 283px !important;
            animation: fade-in-up .25s ease forwards;

            right: unset;
            left: 10px !important;
            top: unset !important;
            bottom: 10px;
            z-index: 100 !important;
        }
    }

    .sweet-content {
        overflow-y: auto;
    }

    .banner-free {
        position: fixed;
        height: 50px !important;
        width: 100%;
        top: 0;
        text-align: center;
        z-index: 99999;
        padding-top: 5px;
        font-family: Muli, sans-serif;

        background: #FFFFFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        .banner-free-first-text,
        .banner-free-second-text,
        .banner-free-third-text {
            color: #000000;
            margin-right: 5px;
        }

        .banner-free-second-text {
            color: #882cfd;
        }

        .banner-free-img {
            width: 110px;
        }

        .btn-banner-free {
            width: 160px;
            height: 40px;
            background: #7e1afd;
            border-radius: 8px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            color: #fff;
        }
    }

    @media (max-width: 800px) {
        .banner-free {
            font-size: 12px;
            padding-top: 0;

            .btn-banner-free {
                font-size: 12px;
            }

            .banner-free-third-text {
                display: none;
            }
        }
    }

    .sweet-modal-helper-ios-15 {
        .sweet-modal {
            top: 10px;
            max-height: calc(90vh - 10px);

            .sweet-content-content {
                max-height: 85vh;
            }
        }
    }

    .sweet-modal-helper-ios {
        .sweet-modal {
            .sweet-content-content {
                max-height: calc(100vh - 110px);
            }
        }
    }

    @media (max-width: 600px) {
        .banner-free {
            .btn-banner-free {
                position: absolute;
                left: calc(50% - 80px);
                top: 30px;
            }
        }
    }

    @media (max-width: 600px) {
        .banner-free {
            .banner-free-img {
                display: none;
            }
        }
    }

    .page-mask-active {
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .invisible {
        visibility: hidden;
    }

    [data-device="desktop"] {
        [data-fixed="true"]:not([data-header-modify-on-scroll]):not([data-footer-fixed="true"]) {
            position: fixed;
            left: 0;
            width: 100%;
            top: 0;
            z-index: 9000; // precisa ser menor que do sweet alert

            &.scroll-fixed-z-index{
                width: calc(100% - 15px);
            }
        }

        .sitebuilder-element-container {
            max-width: initial !important;
        }
    }

    [data-device="mobile"] {
        [data-fixed-mobile="true"]:not([data-header-modify-on-scroll]):not([data-footer-fixed-mobile="true"]) {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 9000; // precisa ser menor que do sweet alert
        }
    }

    [data-device="desktop"] {
      [data-footer-fixed="true"] {
        position: fixed;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: 9000; // precisa ser menor que do sweet alert

        &.scroll-fixed-z-index {
          width: calc(100% - 15px);
        }
      }

      .sitebuilder-element-container {
        max-width: initial !important;
      }
    }

    [data-device="mobile"] {
      [data-footer-fixed-mobile="true"] {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 9000; // precisa ser menor que do sweet alert
      }
    }

    body.free-website [data-fixed="true"] {
        top: 50px;

    }

    [data-device="desktop"] .remove-margin-top,
    [data-device="mobile"] .remove-margin-top-mobile {
        margin-top: 0 !important;
    }

    /* Cookie Dialog */
    #gdpr-cookie-message {
        position: fixed;

        max-width: 600px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);

        font-family: var(--messageFontFamily), sans-serif;
        background-color: var(--backgroundColor);

        z-index: 9000;

        &.bottom-right {
            right: 30px;
            bottom: 30px;

            button#gdpr-cookie-accept {
                position: absolute;
                right: 15px;
            }
        }

        &.top-right {
            right: 30px;
            top: 30px;

            button#gdpr-cookie-accept {
                position: absolute;
                right: 15px;
            }
        }

        &.bottom-left {
            left: 30px;
            bottom: 30px;

            button#gdpr-cookie-accept {
                position: absolute;
                right: 15px;
            }
        }

        &.top-left {
            left: 30px;
            top: 30px;

            button#gdpr-cookie-accept {
                position: absolute;
                right: 15px;
            }
        }

        &.full-bottom {
            bottom: 0;
            left: 0;
            max-width: none;
            width: 100%;

            * {
                max-width: 500px;
                margin: 5px auto;
            }
        }

        &.full-top {
            top: 0;
            left: 0;
            max-width: none;
            width: 100%;
            bottom: unset;

            * {
                max-width: 500px;
                margin: 5px auto;
            }
        }

        &.center-center {
            top: calc(50% - 90px);
            left: calc(50% - 300px);

            button#gdpr-cookie-accept {
                position: absolute;
                right: 15px;
            }
        }

        h4 {
            color: var(--colorTitle);
            font-family: var(--titleFontFamily), sans-serif;
            font-size: var(--titleFontSize);

            font-weight: 500;
            margin-bottom: 10px;
        }

        h5 {
            color: var(--colorTitle);
            font-family: var(--cookieTypesTitleFontFamily), sans-serif;
            font-size: var(--cookieTypesTitleFontSize);

            font-weight: 500;
            margin-bottom: 10px;
        }

        input[type="checkbox"] {
            float: none;
            margin-top: 0;
            margin-right: 5px;
        }

        p:first-of-type {
            max-height: 100px;
            overflow: auto;
        }

        p, ul {
            color: var(--colorParagraph);
            font-size: var(--messageFontSize);

            line-height: 1.5em;

            &:last-child {
                margin-bottom: 0;
                text-align: right;
                padding-inline-start: 0 !important;
            }
        }

        li {
            width: 49%;
            display: inline-block;
            text-align: left;
        }

        a {
            color: var(--colorLink);
            font-family: var(--moreInfoLabelFontFamily), sans-serif;
            font-size: var(--moreInfoLabelFontSize);

            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
            transition: all 0.3s ease-in;

            &:hover {
                color: white;
                border-bottom-color: var(--colorLink);
                transition: all 0.3s ease-in;
            }
        }

        button {
            border: none;

            background: var(--buttonBackground);
            color: var(--buttonColor);
            font-family: var(--acceptBtnLabelFontFamily), sans-serif;
            font-size: var(--acceptBtnLabelFontSize);

            padding: 7px;
            border-radius: 3px;
            margin-left: 15px;
            cursor: pointer;
            transition: all 0.3s ease-in;
            min-height: 58px;
            max-width: 185px;

            &:hover {
                background: var(--buttonColor);
                color: var(--buttonBackground);
                transition: all 0.3s ease-in;
            }

            &:disabled {
                opacity: 0.3;
            }
        }
    }

    button#gdpr-cookie-advanced {
        background: var(--buttonAdvBackground);
        color: var(--buttonAdvColor);
        font-family: var(--advancedBtnLabelFontFamily);
        font-size: var(--advancedBtnLabelFontSize);

        position: relative;
        right: 20px;
        float: left;
        min-height: 40px;
        margin-top: 9px;
    }

    button#gdpr-cookie-only {
        background: var(--buttonAdvBackground);
        color: var(--buttonAdvColor);
        font-family: var(--onlyBtnLabelFontFamily);
        font-size: var(--onlyBtnLabelFontSize);

        position: relative;
        right: 13px;
        float: left;
        min-height: 40px;
        margin-top: 9px;

    }

    #gdpr-reset-cookies {
        border: var(--borderReesetCookies);;
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        padding: 7px;
        border-radius: var(--borderRadiusResetCookies);
        margin-left: 15px;
        cursor: pointer;
        transition: all 0.3s ease-in;
        /*max-width: 30% !important;*/
        height: 40px;
        background: var(--buttonBackgroundResetCookies);
        color: var(--buttonColorResetCookies);
        position: absolute;
        bottom: 0;

        &:hover {
            background: var(--buttonColorResetCookies);
            color: var(--buttonBackgroundResetCookies);
            transition: all 0.3s ease-in;
        }
    }

    [data-device="mobile"] {
        & ~ #gdpr-cookie-message {
            width: auto;
            left: 0 !important;

            button {
                float: left;
                max-width: 100px;
                right: 0;
                height: auto;
                margin-top: 9px;
            }
        }
    }

    .link-action {
        cursor: pointer;
    }

    [data-device="mobile"] {

        .desktop-element {
            display: none !important;
        }

        .sitebuilder-element-container {
            position: relative !important;
            left: 0 !important;
            top: 0 !important;
            height: auto !important;
            width: auto !important;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .container-fluid {
            height: auto !important;
        }

        [element="ImageComponent"] img {
            width: 100% !important;
            max-width: 400px;
            height: auto !important;

        }

        [element="ImageComponent"] {

            text-align: center;
        }

    }

    [data-device="desktop"] {
        .mobile-element {
            display: none !important;
        }

    }

    .modal-loading-overlay {
        display: none;
        position: fixed;
        z-index: 90002;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #FFF;
    }

    body.loading .modal-loading-overlay {
        overflow: hidden;
    }

    body.loading .modal-loading-overlay {
        display: block;
    }

    body.loading-office-checkout .modal-loading-overlay {
      overflow: hidden;
    }

    body.loading-office-checkout .modal-loading-overlay {
      display: block !important;
      background: #fff9 !important;
    }

    body.loading-office-checkout .modal-loading-overlay p {
      display: block !important;
      position: absolute;
      top: calc(50% + 60px);
      left: calc(50% - 250px);
    }

    .loader-spinna {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        z-index: 2;
        background-color: rgba(2, 22, 43, 0.44);
        position: absolute;
        top: 50%;
        left: calc(50% - 25px);
    }

    .loader-spinna:before, .loader-spinna:after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 50px;
        height: 50px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        z-index: 1;
    }

    .loader-spinna:before {
        border: 5px dotted rgba(8, 30, 63, 0);
        box-shadow: inset 0 0 0 3em #ffffff;
    }

    .loader-spinna:after {
        z-index: 0;
        border-width: 10px;
        border-style: solid;
        -o-border-image: linear-gradient(to bottom, #858585 0%, transparent 100%);
        border-image: linear-gradient(to bottom, rgba(40, 40, 40, 0.98) 0%, transparent 100%);
        border-image-slice: 0 0 0 20;
        -webkit-animation: spinnaRot 1s infinite linear;
        animation: spinnaRot 1s infinite linear;
    }

    @-webkit-keyframes spinnaRot {
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @keyframes spinnaRot {
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @media (max-width: 600px) {

        .banner-free .btn-banner-free {
            position: absolute;
            left: calc(50% - 80px);
            top: 17px;
        }

        .banner-free .btn-banner-free {
            width: 140px;
            height: 30px;
            background: #7e1afd;
            border-radius: 8px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 5px;
            color: #fff;
        }

    }

     @media (max-width: 480px) {
        #gdpr-cookie-message {
            p:last-child {
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    width: 100% !important;
                    max-width: none;

                    position: relative !important;
                    right: 0 !important;
                }
            }
        }
    }

    .img-watermark {
        position: absolute;
        bottom: 0;
        max-width: 120px;
        cursor: pointer;

        &.img-side-right {
            right: 0;
        }

        &.img-side-left {
            left: 0;
        }

        &.img-side-center {
            left: calc(50% - 60px);
        }
    }

    .cheetah-modal-video {
        background: rgba(0, 0, 0, 0.25);
      z-index: 9001;
    }

    .hiden-panel{
        display: none;
    }

    [element="GetResponseFormComponent"],
    [element="SimpleFormComponent"],
    [element="MailingBossFormComponent"] {
        select.form-control[multiple],
        select.form-control[size],
        textarea.form-control:not(.form-control-textarea-has-height) {
            height: auto !important;
        }

        .recaptcha-container {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
        }

        .horizontal-form {
            display: flex;

            .inputs-area {
                width: 50%;
            }

            .place-btn {
                width: 50%;
                position: relative;

                .bt-sb {
                    position: absolute;
                    bottom: 15px;
                    left: 0;
                }
            }
        }
    }

</style>
